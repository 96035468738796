/* ---------
HTML
--------- */
html{
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after{
  box-sizing: inherit;
}

html, body, div#root{
  .sq(100%);
}


/* ---------
BODY
--------- */
body, div#root{
  margin: 0;
  line-height: 1;
  .font-size(14, 1.4em);
  #font > #family > .sans-serif();
  color:@white;
  background:#0F1114;
  //-webkit-font-smoothing: antialiased;
}


/* ---------
LINKS
--------- */
a{
  color:#A5B2C4;
  text-decoration:underline;
  text-decoration-skip-ink: auto;
  .transition(all .2s ease-in-out);
}
a:hover{
  color:@white;
  //text-decoration:none;
}



/* ---------
UI
--------- */
select{
  display:inline-block;
  padding:0 30px 0 10px;
  .size(160px, 30px);
  .border-radius(4px);
  border:0;
  #font > #family > .sans-serif();
  .font-size(16, 32px);
  font-weight:400;
  color:#E2E2E2;
  background:url(../img/ico-select.svg) right 10px center no-repeat #2E353E;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
  outline:none;
  cursor:pointer;
  vertical-align:top;
  overflow: hidden;
  text-overflow: ellipsis;
  .transition(all .2s ease-in-out);

  &:hover{
    background-color:@blue;
  }
}

button {
  padding: 0 20px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  color: @white;
  background: @blue;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: @darkblue;
  }

  .red {
    background: @red;
  }
}

input{
  padding:12px 16px;
  height:auto;
  .border-radius(4px);
  #font > #family > .sans-serif();
  .font-size(18, 24px);
  color:@white;
  border:1px solid #2E353E;
  background:#0F1114;
  outline:0;
  .transition(all .2s ease-in-out);

  &:focus{
    outline:0;
    border-color:@blue;
  }
}

.item-checkbox{
  position:relative;
  display:inline-block;
  padding:0 0 0 28px;
  .font-size(14, 20px);
  color:#E2E2E2;
  vertical-align:top;
  cursor:pointer;

  input{
    position:absolute;
    left:0;
    top:0;
    visibility:hidden;

    &:checked + .like-checkbox{
      &:after{
        opacity:1;
      }
    }
  }
  .like-checkbox{
    position:absolute;
    left:0;
    top:0;
    .sq(20px);
    .border-radius(4px);
    background:#0F1114;

    &:after{
      content:'';
      position:absolute;
      left:0;
      top:0;
      .sq(100%);
      background:url(../img/ico-checkbox.svg) center center no-repeat;
      opacity:0;
      .transition(all .2s ease-in-out);
    }
  }
}


/* ---------
LOADING
--------- */
.loading-bar{
  text-align:center;
}
.loading {
  display: inline-block;
  position: relative;
  margin:0 auto 0;
  width: 60px;
  height: 60px;
  text-align:center;

  div {
    transform-origin: 30px 30px;
    animation: loading 1.2s linear infinite;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 29px;
      width: 2px;
      height: 10px;
      background: @blue;
    }
    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }
    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }
    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }
    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }
    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }
    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }
    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }
    &:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }
    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }
    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }
    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }
    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
  }
}
@keyframes loading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}




/* ---------
BTNs
--------- */
.btn-blue,
.btn-blue-inline,
.btn-save,
.btn-new,
.btn-blue-small,
.btn-download{
  display:block;
  width:100%;
  height:50px;
  border:0;
  .border-radius(4px);
  #font > #family > .sans-serif();
  .font-size(18, 50px);
  font-weight:700;
  color:@white;
  background:@blue;
  text-align:center;
  cursor:pointer;
  text-decoration:none;
  .transition(all .2s ease-in-out);

  &:hover{
    color:@blue;
    background:@white;
  }
}
.btn-blue-small{
  margin:0 auto 0;
  padding:0 20px;
  width:auto;
  height:36px;
  .font-size(14, 36px);
}
.btn-save,
.btn-new,
.btn-download{
  display:inline-block;
  margin:0 12px 10px 0;
  padding:0 18px;
  width:auto;
  height:40px;
  .border-radius(10px);
  .font-size(18, 40px);
  vertical-align:top;

  .ico-ios,
  .ico-gplay{
    position:relative;
    top:-2px;
    display:inline-block;
    .size(36px, 20px);
    background-position:0 center;
    background-repeat:no-repeat;
    background-size:auto 20px;
    vertical-align:middle;
  }
  .ico-ios{
    background-image:url(../img/ico-apple.svg);
  }
  .ico-gplay{
    background-image:url(../img/ico-google-play.svg);
  }
}
.btn-save{
  min-width:130px;
  margin:0;
  background:@green;

  &:hover{
    color:@green;
  }

  &:disabled {
    background: @darkgreen;
    color: @darkgrey;
  }
}

.btn-cancel,
.btn-gray{
  display:inline-block;
  min-width:170px;
  height:40px;
  padding:0 18px;
  .border-radius(10px);
  border:1px solid #A5B2C4;
  .font-size(18, 40px);
  font-weight:700;
  color:#E2E2E2;
  background:none;
  vertical-align:top;
  text-decoration:none;
  text-align:center;
  cursor:pointer;
  outline:0;

  &:focus{
    outline:none;
  }
  &:hover{
    color:@black;
    background:#A5B2C4;
  }
}
.btn-danger,
.btn-red-inline,
.btn-red{
  display:inline-block;
  min-width:170px;
  height:40px;
  padding:0 18px;
  .border-radius(10px);
  border:1px solid @red;
  .font-size(18, 40px);
  font-weight:700;
  color:#E2E2E2;
  background:@red;
  vertical-align:top;
  text-decoration:none;
  text-align:center;
  cursor:pointer;
  outline:0;

  &:focus{
    outline:none;
  }

  &:hover {
    background: @darkred;
  }
}

.btn-analytics{
  display:inline-block;
  .size(60px, 30px);
  border:1px solid @blue;
  .border-radius(4px);
  background:url(../img/ico-analytics.svg) center center no-repeat @blue;
  vertical-align:top;
  text-indent:-9999px;

  &:hover{
    background-color:#0F1114;
  }
}
.btn-detail,
.btn-fav{
  display:inline-block;
  margin:0 0 0 6px;
  .sq(30px);
  border:1px solid #405065;
  .border-radius(4px);
  background:url(../img/ico-star.svg) center 46% no-repeat #0F1114;
  vertical-align:top;
  text-indent:-9999px;
  overflow:hidden;
  visibility:hidden;
  opacity: 0;
  .transition(all .2s ease-in-out);

  &.active {
    border-color:#0F1114;
    background-image:url(../img/ico-star-yellow.svg);
    visibility:visible;
    opacity: 1;

    &:hover{
      background-image: url(../img/ico-star-black.svg);
      background-color: #cdc000;
      border-color: #cdc000;
    }
  }

  &:hover{
    border-color:@blue;
    //background-color:@blue;
  }
}

.btn-detail{
  background-image:url(../img/ico-detail.svg);
  background-position:center center;
}

/*.btn-live{
  display:inline-block;
  .size(auto, 30px);
  min-width:100px;
  border:1px solid #405065;
  .border-radius(4px);
  .font-size(18, 30px);
  font-weight:700;
  color:#A5B2C4;
  vertical-align:top;
  text-align:center;
  text-decoration:none;
  .transition(all .2s ease-in-out);

  &:hover{
    border-color:@blue;
  }
}*/

.btn-more{
  display:block;
  margin:0 0 20px 0;
  .font-size(18, 24px);
  color:#E2E2E2;
  text-align:center;
  text-decoration:none;
  cursor: pointer;
  .transition(all .2s ease-in-out);

  &:hover{
    color:@blue;
  }
  i{
    display:inline-block;
    margin:0 0 0 12px;
    .size(18px, 24px);
    background:url(../img/ico-bottom-blue.svg) center center no-repeat;
    vertical-align:top;
  }
}

.btn-remove{
  position:relative;
  display:inline-block;
  padding:0 0 0 26px;
  .font-size(18, 40px);
  color:@red;
  vertical-align:top;
  text-decoration:underline;

  &:before{
    content:'';
    position:absolute;
    left:0;
    top:0;
    .size(22px, 40px);
    background:url(../img/ico-remove.svg) 0 center no-repeat;
  }
}

.btn-download-ico{
  .sq(40px);
  .border-radius(4px);
  background:url(../img/ico-download.svg) center center no-repeat @green;
  text-indent:-9999px;

  &:disabled {
    //background:url(../img/ico-download.svg) center center no-repeat grey;
    cursor: default;
    opacity: 0.2;
  }
}

.btn-blue-inline, .btn-red-inline {
  margin: auto;
  width: auto;
  display: inline-block;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
}

/* ---------
TABS
--------- */
.react-tabs{
  .react-tabs__tab-list{
    margin:0 0 40px 0;
    border-color:#2e353e;
  }
  .react-tabs__tab{
    bottom:0;
    padding:14px 20px;
    border:0;
    font-size:16px;
    line-height:1.1em;
    color:#a5b2c4;
    .transition(all .2s ease-in-out);

    &:hover{
      color:@white;
    }
    &:focus{
      &:after{
        display:none;
      }
    }
  }
  .react-tabs__tab--selected{
    color:@white;
    background:@blue;
  }
}


/* ---------
POPUP
--------- */
.popup-overlay{
  padding:20px;
  background: rgba(0,0,0,0.7) !important;

  .popup-content{
    padding:20px 20px;
    width:100%;
    max-width:400px;
    border:0;
    .border-radius(10px);
    background:#1C2026;
  }
  .close{
    position:absolute;
    right:0;
    top:0;
    width:20px;
    height:20px;
    .border-radius(10px);
    font-size:14px;
    line-height:20px;
    background:#a5b2c4;
  }
  .header{
    margin:0 0 10px 0;
    .font-size(22, 1.2em);
    font-weight:700;
    color:#E2E2E2;
  }
  .content{
    margin:0 0 30px 0;
    font-size:16px;
    line-height:1.4em;
  }
  .actions{
    text-align:center;
  }
  .btn-cancel,
  .btn-danger{
    margin:0 8px;
    min-width:120px;
    padding:0 12px;
    .font-size(16, 40px);
  }
}



/* ---------
BREADCRUMBS
--------- */
.breadcrumbs{
  margin:0 0 22px 0;
  padding:10px;
  border-top:1px solid #2E353E;
  border-bottom:1px solid #2E353E;

  ul{
    width:100%;
    .c;
  }
  li{
    position:relative;
    float:left;
    padding-left:20px;

    &:first-child{
      padding-left:0;

      &:before{
        display:none;
      }
    }
    &:before{
      content:'>';
      position:absolute;
      left:0;
      top:0;
      .size(20px, 100%);
      .font-size(12, 22px);
      color:#2E83F4;
      text-align:center;
    }
    a,
    span{
      .font-size(16, 22px);
      text-decoration:none;
    }
  }
}


/* ---------
SWITCH - LIVE Vs. RECORD
--------- */
.camdetail-switch{
  display:inline-block;
  padding:4px;
  height:30px;
  .border-radius(4px);
  background:@black;
  vertical-align:top;
  white-space:nowrap;

  button{
    display:inline-block;
    padding:0 20px;
    height:22px;
    .border-radius(4px);
    .font-size(12, 22px);
    vertical-align:top;
    text-align:center;
    text-decoration:none;
    background:@black;
    color:#a5b2c4;
    .transition(all .2s ease-in-out);

    &:hover {
      color: @white;
      cursor: pointer;
    }

    &.active {
      color: @white;
      background:@blue;
      cursor:default;
    }
  }
}


/* ---------
LAYOUT
--------- */
.page{
  padding-bottom:60px; /* FOOTER - SUPPORT */
  .size(100%, auto);
  background:#0F1114;
}
.in{
  position:relative;
  margin:0 auto 0;
  padding-left:50px;
  padding-right:50px;
  max-width:1640px;
  width:100%;
  .c;
}



/* ---------
LOGIN
--------- */
.page-login{
  padding-bottom:0;
  .sq(100%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.login-content{
  margin:0 auto 0;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  max-width:300px;
  width:100%;

  h1{
    margin:0 0 50px 0;
    .size(100%, 50px);
    background:url(../img/logo.svg) center center no-repeat;
    background-size:auto 100%;
    text-indent:-9999px;
  }
}
.form-content{
  width:100%;

  p{
    .font-size(14, 1.4em);
    text-align:center;
  }
  .btn-blue{
    margin-top:30px;
    margin-bottom:10px;
  }
}
.form-item{
  margin:0 0 14px 0;

  label{
    display:block;
    margin:0 0 6px 0;
    padding:0 0 0 8px;
    .font-size(16, 1.2em);
    color:@white;
  }
  input{
    margin:0 0 6px 0;
    padding:12px 16px;
    width:100%;
    height:auto;
    .border-radius(4px);
    #font > #family > .sans-serif();
    .font-size(18, 24px);
    color:@white;
    border:1px solid #2E353E;
    background:#0F1114;
    outline:0;
    .transition(all .2s ease-in-out);

    &:focus{
      outline:0;
      border-color:@blue;
    }
  }
}

.form-content-error{
  padding:70px 0 0 0;
  background:url(../img/ico-error.svg) center 0 no-repeat;
  text-align:center;

  h2{
    margin:0 0 10px 0;
    .font-size(22, 1.2em);
    font-weight:700;
    color:#E2E2E2;
  }
  p{
    .font-size(18, 1.4em);
    color:#A5B2C4;
  }
}


/* ---------
LOGGOUT
--------- */
.page-loggout{
  height:100%;
  filter: blur(1rem);
  overflow:hidden;
}
.page-modal-overlay{
  display:none;

  position:fixed;
  left:0;
  top:0;
  .sq(100%);
  z-index:90;

  &.active{
    display:block;
  }

  &.background {
    background: rgba(0,0,0,0.7);
  }
}
div.page-modal-content-reset-position {
  position: absolute;
  top: 0;
  left: 0;
  .page-modal-content{
    display:none;

    position:fixed;
    left:50%;
    top:50%;
    margin:-200px 0 0 -260px;
    padding:50px 20px;
    .size(520px, auto);
    background:@black;
    background:#1C2026;
    text-align:center;
    z-index:100;

    &.active{
      display:block;
    }

    div.modal-form {
      margin: 30px;
      label {
        display: block;
        margin-top: 15px;
        text-align: left;
        .font-size(18, 1.2em);
      }
      input {
        width: 100%;
      }
    }

    h3{
      &.bottom-margin {
        margin:0 0 70px 0;
      }
      .font-size(22, 1.2em);
      font-weight:700;
      color:#E2E2E2;
    }
    .btn-gray,
    .btn-red{
      margin:0 10px;
    }
  }
}

/* ---------
HEADER
--------- */
header{
  padding:30px 0;
  width:100%;

  h1{
    position:absolute;
    left:0;
    top:0;
    float:left;
    .sq(40px);

    a{
      display:block;
      .sq(100%);
      background:url(../img/logo-small.svg) center center no-repeat;
      background-size:100% auto;
      text-indent:-9999px;
    }
  }

  @media screen and (max-width: 1670px) {
    h1{
      left:20px;
    }
  }

  @media screen and (max-width: 1280px) {
    padding:18px 0;

    h1{
      left:30px;
      .sq(32px);
    }
  }


  @media screen and (max-width: 700px) {
    padding:14px 0;

    h1{
      left:20px;
      .sq(28px);
    }
  }

  @media screen and (max-width: 480px) {
    padding:10px 0;

    h1{
      left:10px;
    }
  }

  @media screen and (max-width: 380px) {
      h1{
        .sq(22px);
      }
  }
}

/* ---------
NAV
--------- */
nav{
  float:left;

  ul{
    float:left;
    .c;
  }
  li{
    float:left;
    margin:0 0 0 60px;
  }
  a{
    display:block;
    position:relative;
    height:40px;
    .font-size(18, 40px);
    color:@white;
    text-align:center;
    text-decoration:none;

    &:hover{
      color:@blue;
    }
  }
  .item-act{
    font-weight:700;
    color:@blue;

    &:before{
      content:'';
      position:absolute;
      left:50%;
      top:-8px;
      margin-left:-4px;
      .sq(8px);
      .border-radius(4px);
      background:@blue;
    }
  }

  @media screen and (max-width: 1280px) {
    padding-left:20px;

    li{
      margin:0 0 0 30px;
    }
    a{
      height:32px;
      .font-size(16, 32px);
    }
  }

  @media screen and (max-width: 700px) {
    padding-left:20px;

    li{
      margin:0 0 0 28px;
    }
    a{
      height:28px;
      .font-size(14, 28px);
    }
    .item-act{
      &:before{
        top:-4px;
        .sq(4px);
        margin-left:-2px;
      }
    }
  }

  @media screen and (max-width: 560px) {
    padding-left:20px;

    li{
      margin:0 0 0 20px;
    }
    a{
      .font-size(12, 28px);
    }
  }

  @media screen and (max-width: 380px) {
    //padding-left:20px;
    li{
      margin:0 0 0 12px;
    }
    a{
      height:22px;
      .font-size(12, 22px);
    }
    .item-act{
      &:before{
        top:-2px;
      }
    }
  }
}

/* ---------
NAV
--------- */
.user-nav{
  float:right;
  padding-right:12px;

  button {
    font-size: 20px;
    color: white;
    background-position:center center;
    background-repeat:no-repeat;
    background-color:#405065;
    width: 40px;
    height: 40px;
    margin-left: 13px;

    &:hover{
      background-color:#272E38;
    }

    &[aria-label="logout"] {
      background-color: @red;
      &:hover{
        background-color:#B20D34;
      }
    }

    svg {
      height: 2.2rem;
      width: 2.2rem;
    }
    @media screen and (max-width: 1280px) {
      width: 30px;
      height: 30px;
      svg {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
    @media screen and (max-width: 419px) {
      width: 25px;
      height: 25px;
      margin-left: 5px;
      svg {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
  }
}
.user-nav-detail{
  span.username {
    font-size: 18px;
    color: #A5B2C4;
    @media screen and (max-width: 1280px) {
      font-size: 16px;
    }
    @media screen and (max-width: 650px) {
      display: none;
    }
  }
}

/* ---------
SEARCH - nepouziva se
--------- */
/*.form-search{
  display:inline-block;
  margin:0 14px 0 0;
  vertical-align:top;

  label{
    position:relative;
    display:block;
    padding:0 40px 0 0;
    .size(430px, 40px);
    .border-radius(20px);
    background:#1C2026;
  }
  input{
    padding:8px 30px;
    .size(100%, auto);
    border:0;
    .border-radius(20px);
    #font > #family > .sans-serif();
    .font-size(18, 24px);
    color:#A5B2C4;
    background:none;
    overflow:hidden;
    outline:0;

    &:focus{
      outline:0;
    }
  }
  button{
    position:absolute;
    right:0;
    top:0;
    .sq(40px);
    .border-radius(20px);
    background:url(../img/ico-search.svg) center center no-repeat #405065;
    overflow:hidden;
    text-indent:-9999px;
    cursor:pointer;
    outline:0;
    .transition(all .2s ease-in-out);

    &:focus{
      outline:0;
    }
    &:hover{
      background-color:@blue;
    }
  }
}*/




/* ---------
FOOTER
--------- */
footer{
  position:relative;
  width:100%;
  z-index:10;
}
.support{
  position:fixed;
  right:50px;
  bottom:0;
  text-align:right;

  &.active{
    .support-content{
      display:block;
    }
  }
}
.support-btn{
  display:inline-block;
  padding:0 20px 0 50px;
  height:36px;
  .border-radius(4px 4px 0 0);
  .font-size(16, 38px);
  font-weight:700;
  color:#0F1114;
  background:url(../img/ico-support.svg) 14px center no-repeat @white;
  background-size:auto 18px;
  white-space:nowrap;
  text-decoration:none;

  &:hover{
    color:@blue;
  }
}
.support-content{
  display:none;
  padding:26px 20px;
  .border-radius(4px 0 0 0);
  background:@white;
  text-align:center;

  h3{
    margin:0 0 30px 0;
    .font-size(22, 1.2em);
    font-weight:700;
    color:#0F1114;
  }
}
.support-type-phone,
.support-type-email{
  display: inline-block;
  position:relative;
  padding:50px 20px 0;
  .font-size(16, 1.2em);
  color:#0F1114;
  background-position:center 0;
  background-repeat:no-repeat;
  background-size:30px auto;
  vertical-align:top;
  text-decoration:none;

  &:hover{
    color:@blue;
  }
}
.support-type-phone{
  background-image:url(../img/ico-support.svg);

}
.support-type-email{
  background-image:url(../img/ico-email.svg);
}



/* ---------
SECTION
--------- */
section{
  padding:30px 0 0 0;
  width:100%;
}



/* ---------
DASHBOARD
--------- */
.dashboard{
  width:100%;
  border-bottom:1px solid #2E353E;
}
.camera-item, .chart-item{
  border-top:1px solid #2E353E;
}
.camera-item-act, .chart-item-act {
  .camera-item-head, .chart-header {
    .ico-arrow{
      .rotate(-180deg);
    }
  }
  .camera-item-content{
    //display:block;
    //max-height:1000px;
    //.transition(all 1s ease-in-out);
  }
}
.camera-item-head, .chart-header {
  position:relative;
  padding:12px 60px 12px 12px;
  line-height:40px;
  cursor:pointer;

  &:hover{
    h2{
      color:@blue;
    }
  }
  h2{
    display:inline-block;
    margin:0;
    .font-size(22, 1.2em);
    font-weight:700;
    vertical-align:middle;
    .transition(all .2s ease-in-out);
  }
  .ico-arrow{
    position:absolute;
    right:12px;
    top:50%;
    margin-top:-20px;
    .border-radius(20px);
    .sq(40px);
    background:url(../img/ico-bottom-white.svg) center center no-repeat @blue;
    .transition(all .2s ease-in-out);
  }
}

/* ---------
CAMERA LIST
--------- */
.camera-list-4{
  margin:0 -8px;
  padding:10px 12px;
  .font-size(0,0);

  .camera-list-item{
    width:25%;
  }
}
.camera-list-item{
  display:inline-block;
  margin:0 0 32px 0;
  padding:0 8px;
  vertical-align:top;
}



/* ---------
CAMERA ITEM
--------- */
.camera{
  width:100%;

  &:hover {
    .camera-head-action {
      .btn-detail,
      .btn-fav{
        opacity:1;
        visibility:visible;
      }
    }
  }
  .video-js{
    position:relative;
    padding-bottom:62.5%;
    .size(100%, auto);

    video{
      position:absolute;
      left:0;
      top:0;
    }
  }
}
.camera-head{
  position:relative;
  padding:0 120px 10px 0;
  width:100%;
  overflow:hidden;

  h3{
    float:left;
    width:100%;
    white-space:nowrap;

    a{
      display:inline-block;
      max-width:100%;
      .font-size(18, 30px);
      color:inherit;
      text-decoration:none;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover{
        color:@blue;
      }
    }
  }
}
.camera-head-select{
  padding-right:460px;
}
.camera-head-action{
  position:absolute;
  right:0;
  top:0;
  text-align:right;
  .transition(all .2s ease-in-out);
}
.camera-head-switch{
  display:inline-block;
  margin:0 8px 0 0;
  vertical-align:top;

  p{
    display:inline-block;
    padding:0 8px 0 0;
    .font-size(18, 30px);
    color:#405065;
  }
}
.camera-content,
.camera-content-loading,
.camera-content-playing{
  position:relative;
  width:100%;
  padding-bottom:62.5%;
  .border-radius(4px);
  background:@black;
  overflow:hidden;
  cursor:pointer;

  &:hover{
    &:after{
      opacity:0.6;
    }
  }
  &:before{
    content:'';
    position:absolute;
    left:0;
    top:0;
    .sq(100%);
    background:url(../img/ico-play.svg) center center no-repeat;
    z-index:3;
    .transition(all .2s ease-in-out);
  }
  &:after{
    content:'';
    position:absolute;
    left:0;
    top:0;
    .sq(100%);
    background:@blue;
    opacity:0;
    z-index:2;
    .transition(all .2s ease-in-out);
  }
  &.error {
    font-size: 16px;
    &:before {
      background: none;
    }
    div.message {
      width: 100%;
      text-align: center;
      margin-top: 40px;
      margin-bottom: -40px;
    }
    }
  img{
    position:absolute;
    left:0;
    top:0;
    .sq(100%);
  }
}
.camera-content-loading {
  &:before {
    background: url("../img/loading.svg") center center no-repeat;
    background-size:60px auto;
  }
}



/* ---------
RECORD
--------- */
.record{
  position:relative;
  width:100%;
  .c;
}

/* ---------
RECORD - PLAYER
--------- */
.record-content{
  float:left;
  width:74%;
}
.record-head{
  position:relative;
  padding:0 230px 8px 12px;

  h2{
    display:inline-block;
    margin:0 8px 0 0;
    .font-size(20, 30px);
    font-weight:700;
    vertical-align:top;
    letter-spacing:0.02em;
  }
  select{
    margin-right:25px;
  }
}
.record-head-item{
  display:inline-block;
  vertical-align:top;
}
.record-head-action{
  position:absolute;
  right:0;
  top:0;
  text-align:right;
}
.record-player{
  position:relative;
  padding-bottom:62.5%;
  width:100%;
  .border-radius(8px);
  background:#1C2026;

  .video-js,
  video_{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
  }
}
.record-player-error{
  position:absolute;
  left:0;
  top:0;
  padding:0 15px;
  .sq(100%);
  background:@black;
  z-index:10;

  p{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display:block;
    margin:0;
    width:100%;
    height:auto;
    .font-size(18, 1.4em);
    color:@white;
    text-align:center;

    a{
      color:@blue;
    }
  }
}

/* ---------
RECORD - CLIP
--------- */
.record-clip{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  padding:14px;
  .border-radius(0 0 4px 4px);
  background:#1C2026;
}
.record-clip-editor{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height:40px;
  .border-radius(4px 0 0 4px);
  overflow:hidden;
  flex-grow: 1;
  flex-shrink:0;

  .clip-start,
  .clip-end{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .clip-start{
    border-right:1px solid #1C2026;

    i{
      margin-left:14px;
    }
  }
  .clip-end{
    button {
      order:2;
      .border-radius(0 4px 4px 0);
    }
    input{
      order:1;
    }
    i{
      margin-right:14px;
    }
  }
  button{
    flex-grow: 0;
    flex-shrink:0;
    display:block;
    padding:0 20px;
    height:40px;
    font-size:16px;
    line-height:40px;
    color:@white;
    background:@blue;
    cursor: pointer;
    &:hover {
      background: @darkblue;
    }

    i{
      position:relative;
      top:-2px;
      display:inline-block;
      .sq(16px);
      background:url(../img/ico-clip-editor.svg) center center no-repeat;
      vertical-align:middle;
    }
  }
  input{
    padding:0 12px;
    width:100px;
    height:40px;
    border:0;
    font-size:16px;
    line-height:40px;
    color:#E2E2E2;
    background:#0F1114;
    text-align:center;
    white-space:nowrap;
    outline:none;

    &:focus{
      outline:0;
    }
  }
}
.record-clip-settings{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding-left:14px;
}
.record-clip-settings-params{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;

  min-height:40px;
  //line-height:40px;

  p{
    display:inline-block;
    margin:0 24px 0 0;
    font-size:14px;
    line-height:1.4em;
    color:#E2E2E2;
    vertical-align:middle;
  }
}


/* ---------
RECORD - ASIDE
--------- */
.record-aside{
  position:absolute;
  right:0;
  top:0;
  padding-left:16px;
  .size(26%, 100%);

  overflow-x:hidden;
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;

  &::-webkit-scrollbar{
    width: 12px;
    background-color: #2E83F4;
  }
  &::-webkit-scrollbar-track{
    border-left:5px solid #0F1114;
    border-right:5px solid #0F1114;
    background-color: #2E353E;
  }
  &::-webkit-scrollbar-thumb{
    .border-radius(6px);
    border:2px solid #0F1114;
    border-top:0;
    border-bottom:0;
    background-color: #2E83F4;
  }
}


/* ---------
RECORD - ASIDE - LIVE switch
--------- */
.record-aside-hide{
  overflow:hidden;
}
.record-aside-live-info{
  position:absolute;
  left:0;
  top:0;
  padding:0 0 0 20px;
  .sq(100%);
  .border-radius(4px);
  background:rgba(15,17,20,.9);
  text-align:center;
  overflow:hidden;
  z-index:10;

  h3{
    margin:0 0 16px 0;
    padding:14px;
    .border-radius(4px);
    .font-size(18, 22px);
    font-weight:700;
    color:#E2E2E2;
    background:@red;
    letter-spacing:0.02em;

    &:before{
      content:'';
      position:relative;
      display:inline-block;
      margin:0 18px 0 0;
      .size(30px, 22px);
      background:url(../img/ico-broadcast.svg) center center no-repeat;
      background-size:100% auto;
      vertical-align:top;
    }
  }
  a{
    display:block;
    width:100%;
    height:100%;
    .border-radius(4px);
    .font-size(18, 200px);
    background:#1C2026;
    text-align:center;
  }
}

/* ---------
RECORD - ASIDE - NAV / ACTION
--------- */
.record-aside-action{
  position:relative;
  margin:0 0 8px 0;
  padding:0 62px;
  .size(100%, 50px);
  .border-radius(4px);
  background:#2E353E;

  .btn-prev,
  .btn-next{
    position:absolute;
    top:0;
    .size(62px, 100%);
    text-indent:-9999px;
    overflow:hidden;

    &:hover{
      background-color:@blue;
    }
    &:before{
      content:'';
      position:absolute;
      left:0;
      top:0;
      .sq(100%);
      background-image:url(../img/ico-bottom-white.svg);
      background-position:center center;
      background-repeat:no-repeat;
    }
  }
  .btn-prev{
    left:0;
    .border-radius(4px 0 0 4px);

    &:before{
      .rotate(90deg);
    }
  }
  .btn-next{
    right:0;
    .border-radius(0 4px 4px 0);

    &:before{
      .rotate(-90deg);
    }
  }
  .btn-select-date{
    display:block;
    width:100%;
    height:50px;
    border-left:1px solid #0F1114;
    border-right:1px solid #0F1114;
    .font-size(14, 50px);
    color:#A5B2C4;
    text-align:center;
    text-decoration:none;
    cursor:pointer;

    &.active,
    &:hover{
      background-color:@blue;
      color:@white;
    }
    strong{
      margin:0 6px 0 0;
      .font-size(18, 50px);
      font-weight:700;
      color:@white;
    }
    .ico-edit{
      position:relative;
      top:-2px;
      display:inline-block;
      margin:0 0 0 20px;
      .sq(16px);
      background:url(../img/ico-edit.svg) center center no-repeat;
      vertical-align:middle;
    }
  }
}

/* ---------
RECORD - ASIDE - SETTINGS
--------- */
.record-aside-settings{
  position:relative;
  margin:0 0 8px 0;
  width:100%;
  height:auto;
  .border-radius(0 0 4px 4px);
  background:#1C2026;
  text-align:center;
  overflow:hidden;
  z-index:4;
  .transition(all .2s ease-in-out);

  &.hidden {
    margin:0;
    height:0;
  }
}
.record-aside-settings-content{
  width:100%;
  padding:22px;

  .item-checkbox{
    margin:20px auto 32px auto;
    text-align:center;
  }
  .row-from,
  .row-to{
    margin:0 0 10px 0;
    width:100%;
    overflow:hidden;

    span{
      float:left;
      width:14%;
      .font-size(16, 40px);
      font-weight:700;
      text-align:left;
    }
    .row-item-date,
    .row-item-time{
      float:left;
      padding-left:10px;
      height:40px;

      input{
        padding:0;
        .size(100%, 100%);
        border:0;
        .border-radius(4px);
        #font > #family > .sans-serif();
        .font-size(16, 40px);
        font-weight:normal;
        color:#E2E2E2;
        background:#0F1114;
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance:none;
        outline:none;
        text-align:center;
      }
      input::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    }
    .row-item-date{
      width:100%;
    }
  }
  .MuiFormControl-root{
    width:100%;
  }
}

/* ---------
RECORD - ASIDE - LIST
--------- */
.record-aside-list{
  width:100%;

  .no-items {
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgb(0,0,0) 70%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 40px 30px 50px 30px;
    font-size: 18px;
    line-height: 1.4em;
    text-align: center;

    strong {
      font-weight: lighter;
      color: #2E83F4;
    }

    button {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 50px;
      background: #2E83F4;
      color: white;
      width: 165px;
      height: 40px;
      border-radius: 10px;
    }
  }
  ul{
    width:100%;
    &.no-items-list {
      position: relative;
    }
  }
  li{
    display:block;
    margin:8px 0 0 0;
    width:100%;

    &:first-child{
      margin-top:0;
    }
  }
  a{
    position:relative;
    display:block;
    padding:0 100px 0 90px;
    .border-radius(4px);
    .size(100%, 40px);
    line-height:40px;
    background:#1C2026;
    overflow:hidden;
    text-decoration:none;

    &:hover{
      background:@blue;

      .info{
        color:@white;
      }
    }

    &.inactive {
      background: #0a0707;
    }

    .ico-person,
    .ico-car,
    .ico-animal,
    .ico-light,
    .ico-other{
      position:absolute;
      left:0;
      top:0;
      .size(70px, 100%);
      background-color:@red;
      background-position:center center;
      background-repeat:no-repeat;
    }
    .ico-person{
      background-image:url(../img/ico-person.svg);
    }
    .ico-car{
      background-image:url(../img/ico-car.svg);
    }
    .ico-animal{
      background-image:url(../img/ico-animal.svg);
    }
    .ico-light{
      background-image:url(../img/ico-light.svg);
    }
    .ico-other{
      background-color:#405065;
      background-image:url(../img/ico-other.svg);
    }
    .date{
      display:inline-block;
      .font-size(18, 1.2em);
      color:#E2E2E2;
      vertical-align:middle;
    }
    .info{
      position:absolute;
      right:0;
      top:0;
      padding:0 20px 0 0;
      .font-size(14, 40px);
      color:#A5B2C4;
    }
  }
  .visited{
    opacity:0.2;
  }
}




/* ---------
ANALYTICS
--------- */
.analytics{
  background:url(../img/analytics-bg.svg) right center no-repeat;
  background-size:auto 100%;

  .item-desc{
    padding:260px 0;
    max-width:40%;
    text-align:left;
  }
  h1{
    margin:0 0 20px 0;
    .font-size(40, 1.2em);
    font-weight:700;
    color:#E2E2E2;
  }
  p{
    .font-size(18, 1.4em);
    color:#A5B2C4;
  }
}



/* ---------
MOBILE APP
--------- */
.mobileapp{
  //padding:30px 0 0 0;
  .c;
}

/* ---------
MOBILE APP - LIST
--------- */
.mobileapp-content{
  float:left;
  padding-right:230px;
  width:66%;

  h1{
    display:block;
    margin:0 0 35px 0;
    .font-size(22, 1.2em);
    font-weight:700;
    color:@white;
  }
  .p-empty{
    margin:30px 0;
    padding:0 0 0 40px;
    .font-size(18, 26px);
    background:url(../img/ico-phone.svg) 10px center no-repeat;
  }
}
.mobileapp-list{
  margin:0 0 40px 0;
  border-top:1px solid #2E353E;

  ul{
    width:100%;
  }
  li{
    width:100%;
    border-bottom:1px solid #2E353E;
  }
  .item-head{
    position:relative;
    padding:10px 60px 10px 42px;
    width:100%;
    min-height:70px;
    line-height:50px;
    background:url(../img/ico-phone-blue.svg) 10px center no-repeat;
    cursor:pointer;
    .c;

    &:hover{
      h3{
        color:@blue;
      }
    }
    h3{
      display:inline-block;
      max-width:100%;
      .font-size(18, 1.2em);
      color:#E2E2E2;
      vertical-align:middle;
      overflow: hidden;
      text-overflow: ellipsis;
      .transition(all .2s ease-in-out);
    }
    span{
      position:absolute;
      right:0;
      top:0;
      .size(50px, 100%);
      background:url(../img/ico-edit.svg) center center no-repeat;
      text-indent:-9999px;
    }
  }
}

/* ---------
MOBILE APP - LIST - phone detail
--------- */
.mobileapp-item-content{
  padding:10px 10px 24px 10px;

  .item-name{
    margin:0 -10px 16px;

    input{
      padding:12px 16px;
      .size(100%, auto);
      .border-radius(4px);
      border:1px solid #ccc;
      #font > #family > .sans-serif();
      .font-size(18, 22px);
      color:@white;
      background:#0F1114;
      outline:0;
      .transition(all .2s ease-in-out);

      &:focus{
        outline:0;
        border-color:@blue;
      }
    }
  }
  .item-hash{
    margin:0 0 30px 0;
    padding:12px 14px;
    .border-radius(4px);
    background:@black;

    span{
      .font-size(14, 1.4em);
      color:#A5B2C4;
      overflow-wrap: break-word;
    }
  }

  h4{
    margin:0 0 24px 0;
    .font-size(18, 1.2em);
    font-weight:700;
    color:#E2E2E2;
  }
  .item-object-list{
    padding:0 0 50px 0;
    .font-size(0,0);

    label{
      position:relative;
      display:inline-block;
      margin:0 0 20px 0;
      padding:0 20px 0 32px;
      width:33.333%;
      .font-size(18, 22px);
      vertical-align:top;
      cursor:pointer;
      .transition(all .2s ease-in-out);

      &:hover{
        color:@blue;
      }
    }
    input{
      position:absolute;
      left:2px;
      top:2px;
      visibility:hidden;
    }
    input:checked + .like-checkbox{
      border-color:@blue;

      &:after{
        visibility:visible;
        opacity:1;
        .scale(1);
      }
    }
    .like-checkbox{
      position:absolute;
      left:0;
      top:0;
      .sq(20px);
      .border-radius(4px);
      border:1px solid #A5B2C4;
      .transition(all .2s ease-in-out);

      &:after{
        content:'';
        position:absolute;
        left:0;
        top:0;
        .sq(100%);
        background:url(../img/ico-checkbox.svg) center center no-repeat;
        visibility:hidden;
        opacity:0;
        .scale(0.2);
        .transition(all .2s ease-in-out);
      }
    }
  }

  .item-footer{
    .c;

    .btn-remove{
      float:left;
      display: inline;
      background-color: transparent;
    }
    .btn-save{
      float:right;
    }
  }
}


/* ---------
MOBILE APP - right aside
--------- */
.mobileapp-install{
  float:right;
  width:34%;

  h2{
    display:block;
    margin:0 0 35px 0;
    .font-size(22, 1.2em);
    font-weight:700;
    color:@blue;
  }
}
.mobileapp-install-phone{
  float:left;
  width:302px;
  height:auto;

  i{
    display:block;
    padding-bottom:183.2%;
    .size(100%, auto);
    background:url(../img/img-mobile.png) 0 0 no-repeat;
    background-size:100% auto;
  }
}
.mobileapp-install-apps{
  float:right;
  text-align:center;

  .item{
    margin:0 0 50px 0;
    width:140px;
  }
  .ico-gplay,
  .ico-ios{
    display:block;
    margin:0 0 16px 0;
    .size(100%, 36px);
    background-position:center 0;
    background-repeat:no-repeat;
  }
  .ico-gplay{
    background-image:url(../img/ico-android.svg);
  }
  .ico-ios{
    background-image:url(../img/ico-apple.svg);
  }
  .item-img{
    position:relative;
    margin:0 auto 18px;
    .sq(112px);
    //padding-bottom:80%;
    //max-width:112px;
    background:@white;

    svg {
      position:absolute;
      left:0;
      top:0;
      .sq(100%);
    }
  }
  p{
    .font-size(18, 1.5em);

    a{
      font-weight:700;
      color:@blue;
    }
  }
}





/* ---------
SETTINGS
--------- */
.settings{
  margin:0 auto 0;
  padding:20px 24px 24px 24px;
  max-width:1000px;
  .border-radius(4px);
  background:#1C2026;

  h1{
    display:block;
    margin:0 0 35px 0;
    .font-size(22, 1.2em);
    font-weight:700;
    color:@white;
  }

  .rules-tree {
    margin-bottom: 50px;
    li {
      margin-left: 30px;
      line-height: 20px;
      ul,table {
        margin-bottom: 20px;
      }
    }
  }
}

/* ---------
SETTINGS - FORM
--------- */
.settings-form{
  margin:0 -14px;
  .font-size(0,0);

  h3{
    margin:0 0 14px 0;
    .font-size(18, 1.2em);
    font-weight:700;
    color:#E2E2E2;
  }

  .form-footer{
    padding:0 14px;
    text-align:right;

    .btn-gray{
      margin:0 10px 0 0;
      min-width:120px;
    }
  }

  .form-item-list,
  .alert-rule-list,
  .form-item-4{
    display:inline-block;
    margin:0 0 30px 0;
    padding:0 14px;
    vertical-align:top;
  }
  .form-item-4{
    width:25%;
  }
  label{
    display:block;
    width:100%;

    span{
      display:block;
      margin:0 0 6px 0;
      padding:0 4px;
      .font-size(14, 1.4em);
      color:@white;
    }
    input,
    select{
      padding:8px 12px;
      .size(100%, auto);
      .border-radius(4px);
      border:1px solid #0F1114;
      #font > #family > .sans-serif();
      .font-size(14, 18px);
      color:@white;
      background:#0F1114;
      outline:0;
      .transition(all .2s ease-in-out);

      &:focus{
        outline:0;
        border-color:@blue;
      }

      &.invalid {
        outline: 0;
        border-color: @red;
      }
    }
    select{
      background:url(../img/ico-select.svg) right 10px center no-repeat #0F1114;
    }
  }
  .form-item-list{
    display:block;

    .camerasList {
      margin-left: 32px;
      margin-bottom:20px;
      padding-top:8px;

      label{
        margin-bottom:10px;
      }
    }
  }


  .form-item-checkbox,
  .form-item-radio{
    position:relative;
    //display:inline-block;
    margin:0 0 20px 0;
    padding:0 20px 0 32px;
    //width:33.333%;
    .font-size(14, 22px);
    vertical-align:top;
    cursor:pointer;
    .transition(all .2s ease-in-out);

    &:hover{
      color:@blue;
    }

    input{
      position:absolute;
      left:2px;
      top:2px;
      padding:0;
      .sq(auto);
      visibility:hidden;
    }
  }
  .form-item-checkbox{
    input:checked + .like-checkbox{
      border-color:@blue;

      &:after{
        visibility:visible;
        opacity:1;
        .scale(1);
      }
    }
    .like-checkbox{
      position:absolute;
      left:0;
      top:0;
      .sq(20px);
      .border-radius(4px);
      border:1px solid #A5B2C4;
      background:#0F1114;
      .transition(all .2s ease-in-out);

      &:after{
        content:'';
        position:absolute;
        left:0;
        top:0;
        .sq(100%);
        background:url(../img/ico-checkbox.svg) center center no-repeat;
        visibility:hidden;
        opacity:0;
        .scale(0.2);
        .transition(all .2s ease-in-out);
      }
    }
  }
  .form-item-radio{
    display:inline-block;
    width:auto;

    input:checked + .like-radio{
      border-color:@blue;

      &:after{
        visibility:visible;
        opacity:1;
        .scale(1);
      }
    }
    .like-radio{
      position:absolute;
      left:0;
      top:0;
      .sq(20px);
      .border-radius(10px);
      background:#0F1114;
      border:1px solid #A5B2C4;
      .transition(all .2s ease-in-out);

      &:after{
        content:'';
        position:absolute;
        left:3px;
        top:3px;
        .sq(12px);
        .border-radius(8px);
        background:@blue;
        visibility:hidden;
        opacity:0;
        .scale(0.2);
        .transition(all .2s ease-in-out);
      }
    }
  }
    h3 {
      margin-bottom: 30px;
    }
    .alert-item-checkbox {
      margin-bottom: 30px;
      width: 190px;
      float: left;
      .form-item-checkbox{
        margin: 0;
      }
      span.alert-name {
        display: block;
        width: 100%;
        font-size: 14px;
        margin-bottom: 20px;
      }
      label {
        line-height: 10px;
        width: auto;
      }
    }
}

/* CHECKBOX - OPEN / CLOSE */
.checkbox-item-list{
  margin-bottom:14px;

  .form-item-checkbox{
    margin-bottom:0;
  }
  .form-list-tree-toggle {
    position: relative;
    display:block;
    padding:2px 0 2px  32px;
    font-size: 12px;
    line-height:1.2em;
    color:#a5b2c4;
    opacity:0.6;
    cursor:pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .transition(all .2s ease-in-out);

    &:hover{
      color:@blue;
      opacity:1;
    }
  }
}

div.camera-checkbox-list {
  div.checkbox-item-list {
    float: left;
    width: 190px;
  }
  .clear {
    clear: both;
  }
}



/* ---------
USER LIST - TABLE
--------- */
.torlin-table {
  width:100%;
  max-width:100%;
  overflow:hidden;
  overflow-x:auto;
  overflow-y:hidden;
  -webkit-overflow-scrolling: touch;

  table{
    width:100%;
  }
  tbody{
    tr{
      background:#2E353E;

      &:nth-child(even){
        background:#1C2026;
      }
    }
  }
  th{
    padding:4px 10px 4px 14px;
    border-bottom:4px solid #0F1114;
    color:@white;
    background:#1C2026;
    text-align:left;
  }
  .th-name{
    display:block;
    .font-size(16, 1.2em);
  }
  .th-input{
    input{
      width:165px;
      height:auto;
      padding:4px 8px;
      .border-radius(4px);
      border:1px solid #405065;
      .font-size(14, 18px);
      color:@white;
      background:#0F1114;
      outline:0;

      &:focus{
        outline:0;
        border-color:@blue;
      }
    }
  }
  .th-sort{
    position:relative;
    padding-right:20px;
    width:100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    .btn-sort-down,
    .btn-sort-up,
    .btn-sort{
      position:absolute;
      right:0;
      top:0;
      width:12px;
      height:100%;
      background:url(../img/ico-sort.svg) center center no-repeat;
      text-indent:-9999px;
    }
    .btn-sort-up{
      background-image:url(../img/ico-sort-up.svg);
    }
    .btn-sort-down{
      background-image:url(../img/ico-sort-down.svg);
    }
  }
  td{
    padding:8px 14px;
    border-left:1px solid #0F1114;
    border-bottom:1px solid #0F1114;
    .font-size(14, 1.2em);
    color:@white;

    &:first-child{
      border-left:0;
    }
    &:last-child{
      //padding:0;
      //text-align:center;
    }
  }
  .btn-edit, .btn-delete {
    position:relative;
    display:inline-block;
    .sq(26px);
    background:url(../img/ico-edit.svg) center center no-repeat;
    vertical-align:middle;
    text-indent:-9999px;
    overflow:hidden;
  }

  .btn-delete {
    background-image: url(../img/ico-remove.svg);
  }

  .btn-new {
    float: right;
  }

  div.pagination {
    margin-top: 50px;
    text-align: center;
    color: #A5B2C4;
    font-size: 18px;

    input, select {
      width: 62px;
      padding-left: 14px;
      height: 40px;
      border-radius: 4px;
      background: #0F1114;
      color: #E2E2E2;
      border:1px solid #405065;
      margin-left: 16px;
      margin-right: 16px;
      outline:0;
      text-align: center;
      &:focus{
        border-color:@blue;
      }
    }

    button {
      border-radius: 4px;
      padding: 4px;
      color: #E2E2E2;
      font-size: 30px;
      width: 62px;
      height: 40px;
      &.btn-next {
        background:url(../img/ico-arrow-right-lightgrey.svg) center center no-repeat #2E353E;
        margin-left: 32px;
      }
      &.btn-prev {
        background:url(../img/ico-arrow-left-lightgrey.svg) center center no-repeat #2E353E;
        margin-right: 32px;
      }
    }
  }
  div.on-page {
    position: absolute;
    margin-top: -30px;
    right: 50px;
    line-height: 18px;
    select {
      margin-top: -10px;
      width: 80px;
      background: url('../img/ico-arrow-down-lightgrey.svg') 55px center no-repeat #0F1114;
    }
  }
}

div.react-select {
  font-size: 14px;
  border: 1px solid #405065;
  border-radius: 4px;
}

div.footfall {
  div.footfall-header {
    display: flex;
    div.filter {
      margin-left: 20px;
      margin-right: 30px;
      width: 400px;
    }
  }
  div.footfall-data {
    margin-top: 30px;

    div.chart {
      height: 650px;
      min-width: 100%;
      margin-bottom: 50px;
    }
  }

  div.footfall-data-detail {
    div.charts {
      div.analytics-data {
        margin-top: 10px;
        margin-bottom: 80px;
        div.chart {
          min-width: 100%;
          height: 650px;
          margin-bottom: 50px;
        }
      }

    }
  }
}

div.chart-overall {
  overflow:hidden;
  overflow-x:auto;
  overflow-y:hidden;
  -webkit-overflow-scrolling: touch;
}

div.torlin-datepicker {

  div.react-calendar {
    margin-top: 2px;
    background: @verydarkgrey;
    border-radius: 4px;
    border:1px solid #2E353E;
    padding-bottom: 30px;

    abbr {
      border-bottom: none;
      text-decoration: none;

      &[title] {
        font-size: 14px;
      }
    }

    button {
      border-radius: 0;
      height: 40px;
      line-height: 0;
      padding: 0;
      text-decoration: none;

        &.react-calendar__tile--now {
        font-weight: bold;
        background: transparent;
      }

      &.react-calendar__tile--active {
        background: @blue;
      }

      &.react-calendar__tile--rangeStart {
        background: @darkblue;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }

      &.react-calendar__tile--rangeEnd {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        background: @darkblue;
      }

      &.react-calendar__tile:disabled {
        background: @disablered;
        color: @darkgrey;
      }

      &.react-calendar__navigation__label, &.react-calendar__navigation__arrow {
          background: transparent;
      }
    }
  }

  div.react-daterange-picker__wrapper {
    width:100%;
    padding: 0 10px 0 10px;
    height:auto;
    .border-radius(4px);
    color:@white;
    border:1px solid #2E353E;
    background:#0F1114;
    outline:0;
    .transition(all .2s ease-in-out);

    &:focus{
      outline:0;
      border-color:@blue;
    }

    input {
      color: white;
      &:focus{
        outline:0;
        padding-bottom: 2px;
      }
    }

    svg {
      stroke: white;
    }

    button {
      background: transparent;
    }

    span.react-daterange-picker__range-divider {
      padding-right: 10px;
    }

  }
}
div.RoiSettingFilter {
  div {
    margin-top: 10px;
    div {
      margin-top: 0;
    }
  }
  div.roi-dimension {
    padding: 10px;
    max-width: 100%;
    max-height: 800px;
    overflow: auto;
  }
  button {
    margin-right: 10px;
    &:disabled {
      background: @darkgrey;
      color: grey;
      cursor: default;
    }
  }
}

div.codex-editor {
  border: 1px solid #2e353e;
  border-radius: 5px;
  &:focus-within {
    border-color: @blue;
  }
  div.ce-block__content, div.ce-toolbar__content {
    max-width: 1400px;
    svg {
      fill: @blue;
    }
  }
}

div.codex-editor, div.mails {
  h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 15px
  }
  h3 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  p {
    margin-top: 5px;
    line-height: 2rem;
  }
  br {
    height: 2px;
  }
  figure {
    padding: 10px;
    margin: 20px 0 20px 0;
    border-radius: 5px;
    &.image-block--with-border {
      border: 1px solid @darkgrey;
    }
    figcaption {
      font-style: italic;
      border-top: 1px solid @darkgrey;
      padding-top: 10px;
      text-align: center;
      &::before {
        content: 'Obr: ';
      }
    }
  }
}

div.new-mail-form {
  div.title {
    input {
      width: 1000px;
    }
  }
  div.published, div.title {
    margin-bottom: 1rem;
    input {
      margin-left: 1rem;
      &::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }

    }
  }
  div.edit-mail-buttons {
    margin-top: 1rem;
  }
}

.row-pointer {
  cursor: pointer;
}

div.MuiDataGrid-row {
  &.Mui-even {
    div.MuiDataGrid-cell {
      background: @verydarkgrey;
    }
  }
}