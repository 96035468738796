/*!
 *
 *	Project:
 *	Author:		Petr Urbanek - www.r4ms3s.cz
 *	Twitter:	@r4ms3scz
 *
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
}
html,
body {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
cite,
code,
del,
dfn,
em,
img,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dd,
dl,
dt,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  line-height: 1;
  font-family: inherit;
}
html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
a:focus {
  outline: 0;
}
a:hover,
a:active {
  outline: 0;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  -ms-interpolation-mode: bicubic;
}
button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}
button,
input {
  line-height: normal;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
.c:after {
  content: "";
  display: table;
  clear: both;
}
p {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  text-rendering: optimizelegibility;
}
ul,
ol {
  padding: 0;
  margin: 0;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}
ul {
  list-style: none;
}
ol {
  list-style: none;
}
dl {
  margin-bottom: 0;
}
hr {
  margin: 0;
  border: 0;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
abbr {
  font-size: 90%;
  text-transform: uppercase;
  border-bottom: 1px dotted #ddd;
  cursor: help;
}
small {
  font-size: 100%;
}
cite {
  font-style: normal;
}
/* ---------
HTML
--------- */
html {
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
html,
body,
div#root {
  width: 100%;
  height: 100%;
}
/* ---------
BODY
--------- */
body,
div#root {
  margin: 0;
  line-height: 1;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.4em;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #fff;
  background: #0F1114;
}
/* ---------
LINKS
--------- */
a {
  color: #A5B2C4;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a:hover {
  color: #fff;
}
/* ---------
UI
--------- */
select {
  display: inline-block;
  padding: 0 30px 0 10px;
  width: 160px;
  height: 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 32px;
  font-weight: 400;
  color: #E2E2E2;
  background: url(../img/ico-select.svg) right 10px center no-repeat #2E353E;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
select:hover {
  background-color: #2E83F4;
}
button {
  padding: 0 20px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  color: #fff;
  background: #2E83F4;
  cursor: pointer;
  border-radius: 4px;
}
button:hover {
  background-color: #215fb0;
}
button .red {
  background: #ff003d;
}
input {
  padding: 12px 16px;
  height: auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 24px;
  color: #fff;
  border: 1px solid #2E353E;
  background: #0F1114;
  outline: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
input:focus {
  outline: 0;
  border-color: #2E83F4;
}
.item-checkbox {
  position: relative;
  display: inline-block;
  padding: 0 0 0 28px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 20px;
  color: #E2E2E2;
  vertical-align: top;
  cursor: pointer;
}
.item-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
}
.item-checkbox input:checked + .like-checkbox:after {
  opacity: 1;
}
.item-checkbox .like-checkbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #0F1114;
}
.item-checkbox .like-checkbox:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../img/ico-checkbox.svg) center center no-repeat;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
/* ---------
LOADING
--------- */
.loading-bar {
  text-align: center;
}
.loading {
  display: inline-block;
  position: relative;
  margin: 0 auto 0;
  width: 60px;
  height: 60px;
  text-align: center;
}
.loading div {
  transform-origin: 30px 30px;
  animation: loading 1.2s linear infinite;
}
.loading div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 29px;
  width: 2px;
  height: 10px;
  background: #2E83F4;
}
.loading div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.loading div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.loading div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.loading div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.loading div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.loading div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.loading div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.loading div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.loading div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.loading div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.loading div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.loading div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes loading {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* ---------
BTNs
--------- */
.btn-blue,
.btn-blue-inline,
.btn-save,
.btn-new,
.btn-blue-small,
.btn-download {
  display: block;
  width: 100%;
  height: 50px;
  border: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 50px;
  font-weight: 700;
  color: #fff;
  background: #2E83F4;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.btn-blue:hover,
.btn-blue-inline:hover,
.btn-save:hover,
.btn-new:hover,
.btn-blue-small:hover,
.btn-download:hover {
  color: #2E83F4;
  background: #fff;
}
.btn-blue-small {
  margin: 0 auto 0;
  padding: 0 20px;
  width: auto;
  height: 36px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 36px;
}
.btn-save,
.btn-new,
.btn-download {
  display: inline-block;
  margin: 0 12px 10px 0;
  padding: 0 18px;
  width: auto;
  height: 40px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 40px;
  vertical-align: top;
}
.btn-save .ico-ios,
.btn-new .ico-ios,
.btn-download .ico-ios,
.btn-save .ico-gplay,
.btn-new .ico-gplay,
.btn-download .ico-gplay {
  position: relative;
  top: -2px;
  display: inline-block;
  width: 36px;
  height: 20px;
  background-position: 0 center;
  background-repeat: no-repeat;
  background-size: auto 20px;
  vertical-align: middle;
}
.btn-save .ico-ios,
.btn-new .ico-ios,
.btn-download .ico-ios {
  background-image: url(../img/ico-apple.svg);
}
.btn-save .ico-gplay,
.btn-new .ico-gplay,
.btn-download .ico-gplay {
  background-image: url(../img/ico-google-play.svg);
}
.btn-save {
  min-width: 130px;
  margin: 0;
  background: #019565;
}
.btn-save:hover {
  color: #019565;
}
.btn-save:disabled {
  background: #005e40;
  color: #414141;
}
.btn-cancel,
.btn-gray {
  display: inline-block;
  min-width: 170px;
  height: 40px;
  padding: 0 18px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #A5B2C4;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 40px;
  font-weight: 700;
  color: #E2E2E2;
  background: none;
  vertical-align: top;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  outline: 0;
}
.btn-cancel:focus,
.btn-gray:focus {
  outline: none;
}
.btn-cancel:hover,
.btn-gray:hover {
  color: #000;
  background: #A5B2C4;
}
.btn-danger,
.btn-red-inline,
.btn-red {
  display: inline-block;
  min-width: 170px;
  height: 40px;
  padding: 0 18px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #ff003d;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 40px;
  font-weight: 700;
  color: #E2E2E2;
  background: #ff003d;
  vertical-align: top;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  outline: 0;
}
.btn-danger:focus,
.btn-red-inline:focus,
.btn-red:focus {
  outline: none;
}
.btn-danger:hover,
.btn-red-inline:hover,
.btn-red:hover {
  background: #a90127;
}
.btn-analytics {
  display: inline-block;
  width: 60px;
  height: 30px;
  border: 1px solid #2E83F4;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: url(../img/ico-analytics.svg) center center no-repeat #2E83F4;
  vertical-align: top;
  text-indent: -9999px;
}
.btn-analytics:hover {
  background-color: #0F1114;
}
.btn-detail,
.btn-fav {
  display: inline-block;
  margin: 0 0 0 6px;
  width: 30px;
  height: 30px;
  border: 1px solid #405065;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: url(../img/ico-star.svg) center 46% no-repeat #0F1114;
  vertical-align: top;
  text-indent: -9999px;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.btn-detail.active,
.btn-fav.active {
  border-color: #0F1114;
  background-image: url(../img/ico-star-yellow.svg);
  visibility: visible;
  opacity: 1;
}
.btn-detail.active:hover,
.btn-fav.active:hover {
  background-image: url(../img/ico-star-black.svg);
  background-color: #cdc000;
  border-color: #cdc000;
}
.btn-detail:hover,
.btn-fav:hover {
  border-color: #2E83F4;
}
.btn-detail {
  background-image: url(../img/ico-detail.svg);
  background-position: center center;
}
/*.btn-live{
  display:inline-block;
  .size(auto, 30px);
  min-width:100px;
  border:1px solid #405065;
  .border-radius(4px);
  .font-size(18, 30px);
  font-weight:700;
  color:#A5B2C4;
  vertical-align:top;
  text-align:center;
  text-decoration:none;
  .transition(all .2s ease-in-out);

  &:hover{
    border-color:@blue;
  }
}*/
.btn-more {
  display: block;
  margin: 0 0 20px 0;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 24px;
  color: #E2E2E2;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.btn-more:hover {
  color: #2E83F4;
}
.btn-more i {
  display: inline-block;
  margin: 0 0 0 12px;
  width: 18px;
  height: 24px;
  background: url(../img/ico-bottom-blue.svg) center center no-repeat;
  vertical-align: top;
}
.btn-remove {
  position: relative;
  display: inline-block;
  padding: 0 0 0 26px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 40px;
  color: #ff003d;
  vertical-align: top;
  text-decoration: underline;
}
.btn-remove:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 40px;
  background: url(../img/ico-remove.svg) 0 center no-repeat;
}
.btn-download-ico {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: url(../img/ico-download.svg) center center no-repeat #019565;
  text-indent: -9999px;
}
.btn-download-ico:disabled {
  cursor: default;
  opacity: 0.2;
}
.btn-blue-inline,
.btn-red-inline {
  margin: auto;
  width: auto;
  display: inline-block;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
}
/* ---------
TABS
--------- */
.react-tabs .react-tabs__tab-list {
  margin: 0 0 40px 0;
  border-color: #2e353e;
}
.react-tabs .react-tabs__tab {
  bottom: 0;
  padding: 14px 20px;
  border: 0;
  font-size: 16px;
  line-height: 1.1em;
  color: #a5b2c4;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.react-tabs .react-tabs__tab:hover {
  color: #fff;
}
.react-tabs .react-tabs__tab:focus:after {
  display: none;
}
.react-tabs .react-tabs__tab--selected {
  color: #fff;
  background: #2E83F4;
}
/* ---------
POPUP
--------- */
.popup-overlay {
  padding: 20px;
  background: rgba(0, 0, 0, 0.7) !important;
}
.popup-overlay .popup-content {
  padding: 20px 20px;
  width: 100%;
  max-width: 400px;
  border: 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #1C2026;
}
.popup-overlay .close {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 20px;
  background: #a5b2c4;
}
.popup-overlay .header {
  margin: 0 0 10px 0;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.2em;
  font-weight: 700;
  color: #E2E2E2;
}
.popup-overlay .content {
  margin: 0 0 30px 0;
  font-size: 16px;
  line-height: 1.4em;
}
.popup-overlay .actions {
  text-align: center;
}
.popup-overlay .btn-cancel,
.popup-overlay .btn-danger {
  margin: 0 8px;
  min-width: 120px;
  padding: 0 12px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 40px;
}
/* ---------
BREADCRUMBS
--------- */
.breadcrumbs {
  margin: 0 0 22px 0;
  padding: 10px;
  border-top: 1px solid #2E353E;
  border-bottom: 1px solid #2E353E;
}
.breadcrumbs ul {
  width: 100%;
}
.breadcrumbs ul:after {
  content: "";
  display: table;
  clear: both;
}
.breadcrumbs li {
  position: relative;
  float: left;
  padding-left: 20px;
}
.breadcrumbs li:first-child {
  padding-left: 0;
}
.breadcrumbs li:first-child:before {
  display: none;
}
.breadcrumbs li:before {
  content: '>';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 100%;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 22px;
  color: #2E83F4;
  text-align: center;
}
.breadcrumbs li a,
.breadcrumbs li span {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 22px;
  text-decoration: none;
}
/* ---------
SWITCH - LIVE Vs. RECORD
--------- */
.camdetail-switch {
  display: inline-block;
  padding: 4px;
  height: 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #000;
  vertical-align: top;
  white-space: nowrap;
}
.camdetail-switch button {
  display: inline-block;
  padding: 0 20px;
  height: 22px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 22px;
  vertical-align: top;
  text-align: center;
  text-decoration: none;
  background: #000;
  color: #a5b2c4;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.camdetail-switch button:hover {
  color: #fff;
  cursor: pointer;
}
.camdetail-switch button.active {
  color: #fff;
  background: #2E83F4;
  cursor: default;
}
/* ---------
LAYOUT
--------- */
.page {
  padding-bottom: 60px;
  /* FOOTER - SUPPORT */
  width: 100%;
  height: auto;
  background: #0F1114;
}
.in {
  position: relative;
  margin: 0 auto 0;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 1640px;
  width: 100%;
}
.in:after {
  content: "";
  display: table;
  clear: both;
}
/* ---------
LOGIN
--------- */
.page-login {
  padding-bottom: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.login-content {
  margin: 0 auto 0;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  max-width: 300px;
  width: 100%;
}
.login-content h1 {
  margin: 0 0 50px 0;
  width: 100%;
  height: 50px;
  background: url(../img/logo.svg) center center no-repeat;
  background-size: auto 100%;
  text-indent: -9999px;
}
.form-content {
  width: 100%;
}
.form-content p {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.4em;
  text-align: center;
}
.form-content .btn-blue {
  margin-top: 30px;
  margin-bottom: 10px;
}
.form-item {
  margin: 0 0 14px 0;
}
.form-item label {
  display: block;
  margin: 0 0 6px 0;
  padding: 0 0 0 8px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.2em;
  color: #fff;
}
.form-item input {
  margin: 0 0 6px 0;
  padding: 12px 16px;
  width: 100%;
  height: auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 24px;
  color: #fff;
  border: 1px solid #2E353E;
  background: #0F1114;
  outline: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.form-item input:focus {
  outline: 0;
  border-color: #2E83F4;
}
.form-content-error {
  padding: 70px 0 0 0;
  background: url(../img/ico-error.svg) center 0 no-repeat;
  text-align: center;
}
.form-content-error h2 {
  margin: 0 0 10px 0;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.2em;
  font-weight: 700;
  color: #E2E2E2;
}
.form-content-error p {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.4em;
  color: #A5B2C4;
}
/* ---------
LOGGOUT
--------- */
.page-loggout {
  height: 100%;
  filter: blur(1rem);
  overflow: hidden;
}
.page-modal-overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
}
.page-modal-overlay.active {
  display: block;
}
.page-modal-overlay.background {
  background: rgba(0, 0, 0, 0.7);
}
div.page-modal-content-reset-position {
  position: absolute;
  top: 0;
  left: 0;
}
div.page-modal-content-reset-position .page-modal-content {
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -200px 0 0 -260px;
  padding: 50px 20px;
  width: 520px;
  height: auto;
  background: #000;
  background: #1C2026;
  text-align: center;
  z-index: 100;
}
div.page-modal-content-reset-position .page-modal-content.active {
  display: block;
}
div.page-modal-content-reset-position .page-modal-content div.modal-form {
  margin: 30px;
}
div.page-modal-content-reset-position .page-modal-content div.modal-form label {
  display: block;
  margin-top: 15px;
  text-align: left;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.2em;
}
div.page-modal-content-reset-position .page-modal-content div.modal-form input {
  width: 100%;
}
div.page-modal-content-reset-position .page-modal-content h3 {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.2em;
  font-weight: 700;
  color: #E2E2E2;
}
div.page-modal-content-reset-position .page-modal-content h3.bottom-margin {
  margin: 0 0 70px 0;
}
div.page-modal-content-reset-position .page-modal-content .btn-gray,
div.page-modal-content-reset-position .page-modal-content .btn-red {
  margin: 0 10px;
}
/* ---------
HEADER
--------- */
header {
  padding: 30px 0;
  width: 100%;
}
header h1 {
  position: absolute;
  left: 0;
  top: 0;
  float: left;
  width: 40px;
  height: 40px;
}
header h1 a {
  display: block;
  width: 100%;
  height: 100%;
  background: url(../img/logo-small.svg) center center no-repeat;
  background-size: 100% auto;
  text-indent: -9999px;
}
@media screen and (max-width: 1670px) {
  header h1 {
    left: 20px;
  }
}
@media screen and (max-width: 1280px) {
  header {
    padding: 18px 0;
  }
  header h1 {
    left: 30px;
    width: 32px;
    height: 32px;
  }
}
@media screen and (max-width: 700px) {
  header {
    padding: 14px 0;
  }
  header h1 {
    left: 20px;
    width: 28px;
    height: 28px;
  }
}
@media screen and (max-width: 480px) {
  header {
    padding: 10px 0;
  }
  header h1 {
    left: 10px;
  }
}
@media screen and (max-width: 380px) {
  header h1 {
    width: 22px;
    height: 22px;
  }
}
/* ---------
NAV
--------- */
nav {
  float: left;
}
nav ul {
  float: left;
}
nav ul:after {
  content: "";
  display: table;
  clear: both;
}
nav li {
  float: left;
  margin: 0 0 0 60px;
}
nav a {
  display: block;
  position: relative;
  height: 40px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 40px;
  color: #fff;
  text-align: center;
  text-decoration: none;
}
nav a:hover {
  color: #2E83F4;
}
nav .item-act {
  font-weight: 700;
  color: #2E83F4;
}
nav .item-act:before {
  content: '';
  position: absolute;
  left: 50%;
  top: -8px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #2E83F4;
}
@media screen and (max-width: 1280px) {
  nav {
    padding-left: 20px;
  }
  nav li {
    margin: 0 0 0 30px;
  }
  nav a {
    height: 32px;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 32px;
  }
}
@media screen and (max-width: 700px) {
  nav {
    padding-left: 20px;
  }
  nav li {
    margin: 0 0 0 28px;
  }
  nav a {
    height: 28px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 28px;
  }
  nav .item-act:before {
    top: -4px;
    width: 4px;
    height: 4px;
    margin-left: -2px;
  }
}
@media screen and (max-width: 560px) {
  nav {
    padding-left: 20px;
  }
  nav li {
    margin: 0 0 0 20px;
  }
  nav a {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 28px;
  }
}
@media screen and (max-width: 380px) {
  nav li {
    margin: 0 0 0 12px;
  }
  nav a {
    height: 22px;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 22px;
  }
  nav .item-act:before {
    top: -2px;
  }
}
/* ---------
NAV
--------- */
.user-nav {
  float: right;
  padding-right: 12px;
}
.user-nav button {
  font-size: 20px;
  color: white;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #405065;
  width: 40px;
  height: 40px;
  margin-left: 13px;
}
.user-nav button:hover {
  background-color: #272E38;
}
.user-nav button[aria-label="logout"] {
  background-color: #ff003d;
}
.user-nav button[aria-label="logout"]:hover {
  background-color: #B20D34;
}
.user-nav button svg {
  height: 2.2rem;
  width: 2.2rem;
}
@media screen and (max-width: 1280px) {
  .user-nav button {
    width: 30px;
    height: 30px;
  }
  .user-nav button svg {
    width: 1.75rem;
    height: 1.75rem;
  }
}
@media screen and (max-width: 419px) {
  .user-nav button {
    width: 25px;
    height: 25px;
    margin-left: 5px;
  }
  .user-nav button svg {
    width: 1.75rem;
    height: 1.75rem;
  }
}
.user-nav-detail span.username {
  font-size: 18px;
  color: #A5B2C4;
}
@media screen and (max-width: 1280px) {
  .user-nav-detail span.username {
    font-size: 16px;
  }
}
@media screen and (max-width: 650px) {
  .user-nav-detail span.username {
    display: none;
  }
}
/* ---------
SEARCH - nepouziva se
--------- */
/*.form-search{
  display:inline-block;
  margin:0 14px 0 0;
  vertical-align:top;

  label{
    position:relative;
    display:block;
    padding:0 40px 0 0;
    .size(430px, 40px);
    .border-radius(20px);
    background:#1C2026;
  }
  input{
    padding:8px 30px;
    .size(100%, auto);
    border:0;
    .border-radius(20px);
    #font > #family > .sans-serif();
    .font-size(18, 24px);
    color:#A5B2C4;
    background:none;
    overflow:hidden;
    outline:0;

    &:focus{
      outline:0;
    }
  }
  button{
    position:absolute;
    right:0;
    top:0;
    .sq(40px);
    .border-radius(20px);
    background:url(../img/ico-search.svg) center center no-repeat #405065;
    overflow:hidden;
    text-indent:-9999px;
    cursor:pointer;
    outline:0;
    .transition(all .2s ease-in-out);

    &:focus{
      outline:0;
    }
    &:hover{
      background-color:@blue;
    }
  }
}*/
/* ---------
FOOTER
--------- */
footer {
  position: relative;
  width: 100%;
  z-index: 10;
}
.support {
  position: fixed;
  right: 50px;
  bottom: 0;
  text-align: right;
}
.support.active .support-content {
  display: block;
}
.support-btn {
  display: inline-block;
  padding: 0 20px 0 50px;
  height: 36px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 38px;
  font-weight: 700;
  color: #0F1114;
  background: url(../img/ico-support.svg) 14px center no-repeat #fff;
  background-size: auto 18px;
  white-space: nowrap;
  text-decoration: none;
}
.support-btn:hover {
  color: #2E83F4;
}
.support-content {
  display: none;
  padding: 26px 20px;
  -webkit-border-radius: 4px 0 0 0;
  -moz-border-radius: 4px 0 0 0;
  border-radius: 4px 0 0 0;
  background: #fff;
  text-align: center;
}
.support-content h3 {
  margin: 0 0 30px 0;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.2em;
  font-weight: 700;
  color: #0F1114;
}
.support-type-phone,
.support-type-email {
  display: inline-block;
  position: relative;
  padding: 50px 20px 0;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.2em;
  color: #0F1114;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: 30px auto;
  vertical-align: top;
  text-decoration: none;
}
.support-type-phone:hover,
.support-type-email:hover {
  color: #2E83F4;
}
.support-type-phone {
  background-image: url(../img/ico-support.svg);
}
.support-type-email {
  background-image: url(../img/ico-email.svg);
}
/* ---------
SECTION
--------- */
section {
  padding: 30px 0 0 0;
  width: 100%;
}
/* ---------
DASHBOARD
--------- */
.dashboard {
  width: 100%;
  border-bottom: 1px solid #2E353E;
}
.camera-item,
.chart-item {
  border-top: 1px solid #2E353E;
}
.camera-item-act .camera-item-head .ico-arrow,
.chart-item-act .camera-item-head .ico-arrow,
.camera-item-act .chart-header .ico-arrow,
.chart-item-act .chart-header .ico-arrow {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.camera-item-head,
.chart-header {
  position: relative;
  padding: 12px 60px 12px 12px;
  line-height: 40px;
  cursor: pointer;
}
.camera-item-head:hover h2,
.chart-header:hover h2 {
  color: #2E83F4;
}
.camera-item-head h2,
.chart-header h2 {
  display: inline-block;
  margin: 0;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.2em;
  font-weight: 700;
  vertical-align: middle;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.camera-item-head .ico-arrow,
.chart-header .ico-arrow {
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  background: url(../img/ico-bottom-white.svg) center center no-repeat #2E83F4;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
/* ---------
CAMERA LIST
--------- */
.camera-list-4 {
  margin: 0 -8px;
  padding: 10px 12px;
  font-size: 0px;
  font-size: 0rem;
  line-height: 0;
}
.camera-list-4 .camera-list-item {
  width: 25%;
}
.camera-list-item {
  display: inline-block;
  margin: 0 0 32px 0;
  padding: 0 8px;
  vertical-align: top;
}
/* ---------
CAMERA ITEM
--------- */
.camera {
  width: 100%;
}
.camera:hover .camera-head-action .btn-detail,
.camera:hover .camera-head-action .btn-fav {
  opacity: 1;
  visibility: visible;
}
.camera .video-js {
  position: relative;
  padding-bottom: 62.5%;
  width: 100%;
  height: auto;
}
.camera .video-js video {
  position: absolute;
  left: 0;
  top: 0;
}
.camera-head {
  position: relative;
  padding: 0 120px 10px 0;
  width: 100%;
  overflow: hidden;
}
.camera-head h3 {
  float: left;
  width: 100%;
  white-space: nowrap;
}
.camera-head h3 a {
  display: inline-block;
  max-width: 100%;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 30px;
  color: inherit;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
}
.camera-head h3 a:hover {
  color: #2E83F4;
}
.camera-head-select {
  padding-right: 460px;
}
.camera-head-action {
  position: absolute;
  right: 0;
  top: 0;
  text-align: right;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.camera-head-switch {
  display: inline-block;
  margin: 0 8px 0 0;
  vertical-align: top;
}
.camera-head-switch p {
  display: inline-block;
  padding: 0 8px 0 0;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 30px;
  color: #405065;
}
.camera-content,
.camera-content-loading,
.camera-content-playing {
  position: relative;
  width: 100%;
  padding-bottom: 62.5%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #000;
  overflow: hidden;
  cursor: pointer;
}
.camera-content:hover:after,
.camera-content-loading:hover:after,
.camera-content-playing:hover:after {
  opacity: 0.6;
}
.camera-content:before,
.camera-content-loading:before,
.camera-content-playing:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../img/ico-play.svg) center center no-repeat;
  z-index: 3;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.camera-content:after,
.camera-content-loading:after,
.camera-content-playing:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #2E83F4;
  opacity: 0;
  z-index: 2;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.camera-content.error,
.camera-content-loading.error,
.camera-content-playing.error {
  font-size: 16px;
}
.camera-content.error:before,
.camera-content-loading.error:before,
.camera-content-playing.error:before {
  background: none;
}
.camera-content.error div.message,
.camera-content-loading.error div.message,
.camera-content-playing.error div.message {
  width: 100%;
  text-align: center;
  margin-top: 40px;
  margin-bottom: -40px;
}
.camera-content img,
.camera-content-loading img,
.camera-content-playing img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.camera-content-loading:before {
  background: url("../img/loading.svg") center center no-repeat;
  background-size: 60px auto;
}
/* ---------
RECORD
--------- */
.record {
  position: relative;
  width: 100%;
}
.record:after {
  content: "";
  display: table;
  clear: both;
}
/* ---------
RECORD - PLAYER
--------- */
.record-content {
  float: left;
  width: 74%;
}
.record-head {
  position: relative;
  padding: 0 230px 8px 12px;
}
.record-head h2 {
  display: inline-block;
  margin: 0 8px 0 0;
  font-size: 20px;
  font-size: 2rem;
  line-height: 30px;
  font-weight: 700;
  vertical-align: top;
  letter-spacing: 0.02em;
}
.record-head select {
  margin-right: 25px;
}
.record-head-item {
  display: inline-block;
  vertical-align: top;
}
.record-head-action {
  position: absolute;
  right: 0;
  top: 0;
  text-align: right;
}
.record-player {
  position: relative;
  padding-bottom: 62.5%;
  width: 100%;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background: #1C2026;
}
.record-player .video-js,
.record-player video_ {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.record-player-error {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 15px;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 10;
}
.record-player-error p {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.4em;
  color: #fff;
  text-align: center;
}
.record-player-error p a {
  color: #2E83F4;
}
/* ---------
RECORD - CLIP
--------- */
.record-clip {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 14px;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  background: #1C2026;
}
.record-clip-editor {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 40px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 0;
}
.record-clip-editor .clip-start,
.record-clip-editor .clip-end {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.record-clip-editor .clip-start {
  border-right: 1px solid #1C2026;
}
.record-clip-editor .clip-start i {
  margin-left: 14px;
}
.record-clip-editor .clip-end button {
  order: 2;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.record-clip-editor .clip-end input {
  order: 1;
}
.record-clip-editor .clip-end i {
  margin-right: 14px;
}
.record-clip-editor button {
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
  padding: 0 20px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  color: #fff;
  background: #2E83F4;
  cursor: pointer;
}
.record-clip-editor button:hover {
  background: #215fb0;
}
.record-clip-editor button i {
  position: relative;
  top: -2px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../img/ico-clip-editor.svg) center center no-repeat;
  vertical-align: middle;
}
.record-clip-editor input {
  padding: 0 12px;
  width: 100px;
  height: 40px;
  border: 0;
  font-size: 16px;
  line-height: 40px;
  color: #E2E2E2;
  background: #0F1114;
  text-align: center;
  white-space: nowrap;
  outline: none;
}
.record-clip-editor input:focus {
  outline: 0;
}
.record-clip-settings {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding-left: 14px;
}
.record-clip-settings-params {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  min-height: 40px;
}
.record-clip-settings-params p {
  display: inline-block;
  margin: 0 24px 0 0;
  font-size: 14px;
  line-height: 1.4em;
  color: #E2E2E2;
  vertical-align: middle;
}
/* ---------
RECORD - ASIDE
--------- */
.record-aside {
  position: absolute;
  right: 0;
  top: 0;
  padding-left: 16px;
  width: 26%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
}
.record-aside::-webkit-scrollbar {
  width: 12px;
  background-color: #2E83F4;
}
.record-aside::-webkit-scrollbar-track {
  border-left: 5px solid #0F1114;
  border-right: 5px solid #0F1114;
  background-color: #2E353E;
}
.record-aside::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  border: 2px solid #0F1114;
  border-top: 0;
  border-bottom: 0;
  background-color: #2E83F4;
}
/* ---------
RECORD - ASIDE - LIVE switch
--------- */
.record-aside-hide {
  overflow: hidden;
}
.record-aside-live-info {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 0 0 20px;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: rgba(15, 17, 20, 0.9);
  text-align: center;
  overflow: hidden;
  z-index: 10;
}
.record-aside-live-info h3 {
  margin: 0 0 16px 0;
  padding: 14px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 22px;
  font-weight: 700;
  color: #E2E2E2;
  background: #ff003d;
  letter-spacing: 0.02em;
}
.record-aside-live-info h3:before {
  content: '';
  position: relative;
  display: inline-block;
  margin: 0 18px 0 0;
  width: 30px;
  height: 22px;
  background: url(../img/ico-broadcast.svg) center center no-repeat;
  background-size: 100% auto;
  vertical-align: top;
}
.record-aside-live-info a {
  display: block;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 200px;
  background: #1C2026;
  text-align: center;
}
/* ---------
RECORD - ASIDE - NAV / ACTION
--------- */
.record-aside-action {
  position: relative;
  margin: 0 0 8px 0;
  padding: 0 62px;
  width: 100%;
  height: 50px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #2E353E;
}
.record-aside-action .btn-prev,
.record-aside-action .btn-next {
  position: absolute;
  top: 0;
  width: 62px;
  height: 100%;
  text-indent: -9999px;
  overflow: hidden;
}
.record-aside-action .btn-prev:hover,
.record-aside-action .btn-next:hover {
  background-color: #2E83F4;
}
.record-aside-action .btn-prev:before,
.record-aside-action .btn-next:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../img/ico-bottom-white.svg);
  background-position: center center;
  background-repeat: no-repeat;
}
.record-aside-action .btn-prev {
  left: 0;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.record-aside-action .btn-prev:before {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.record-aside-action .btn-next {
  right: 0;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.record-aside-action .btn-next:before {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.record-aside-action .btn-select-date {
  display: block;
  width: 100%;
  height: 50px;
  border-left: 1px solid #0F1114;
  border-right: 1px solid #0F1114;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 50px;
  color: #A5B2C4;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.record-aside-action .btn-select-date.active,
.record-aside-action .btn-select-date:hover {
  background-color: #2E83F4;
  color: #fff;
}
.record-aside-action .btn-select-date strong {
  margin: 0 6px 0 0;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 50px;
  font-weight: 700;
  color: #fff;
}
.record-aside-action .btn-select-date .ico-edit {
  position: relative;
  top: -2px;
  display: inline-block;
  margin: 0 0 0 20px;
  width: 16px;
  height: 16px;
  background: url(../img/ico-edit.svg) center center no-repeat;
  vertical-align: middle;
}
/* ---------
RECORD - ASIDE - SETTINGS
--------- */
.record-aside-settings {
  position: relative;
  margin: 0 0 8px 0;
  width: 100%;
  height: auto;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  background: #1C2026;
  text-align: center;
  overflow: hidden;
  z-index: 4;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.record-aside-settings.hidden {
  margin: 0;
  height: 0;
}
.record-aside-settings-content {
  width: 100%;
  padding: 22px;
}
.record-aside-settings-content .item-checkbox {
  margin: 20px auto 32px auto;
  text-align: center;
}
.record-aside-settings-content .row-from,
.record-aside-settings-content .row-to {
  margin: 0 0 10px 0;
  width: 100%;
  overflow: hidden;
}
.record-aside-settings-content .row-from span,
.record-aside-settings-content .row-to span {
  float: left;
  width: 14%;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 40px;
  font-weight: 700;
  text-align: left;
}
.record-aside-settings-content .row-from .row-item-date,
.record-aside-settings-content .row-to .row-item-date,
.record-aside-settings-content .row-from .row-item-time,
.record-aside-settings-content .row-to .row-item-time {
  float: left;
  padding-left: 10px;
  height: 40px;
}
.record-aside-settings-content .row-from .row-item-date input,
.record-aside-settings-content .row-to .row-item-date input,
.record-aside-settings-content .row-from .row-item-time input,
.record-aside-settings-content .row-to .row-item-time input {
  padding: 0;
  width: 100%;
  height: 100%;
  border: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 40px;
  font-weight: normal;
  color: #E2E2E2;
  background: #0F1114;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  text-align: center;
}
.record-aside-settings-content .row-from .row-item-date input::-webkit-calendar-picker-indicator,
.record-aside-settings-content .row-to .row-item-date input::-webkit-calendar-picker-indicator,
.record-aside-settings-content .row-from .row-item-time input::-webkit-calendar-picker-indicator,
.record-aside-settings-content .row-to .row-item-time input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.record-aside-settings-content .row-from .row-item-date,
.record-aside-settings-content .row-to .row-item-date {
  width: 100%;
}
.record-aside-settings-content .MuiFormControl-root {
  width: 100%;
}
/* ---------
RECORD - ASIDE - LIST
--------- */
.record-aside-list {
  width: 100%;
}
.record-aside-list .no-items {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, #000000 70%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 40px 30px 50px 30px;
  font-size: 18px;
  line-height: 1.4em;
  text-align: center;
}
.record-aside-list .no-items strong {
  font-weight: lighter;
  color: #2E83F4;
}
.record-aside-list .no-items button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  background: #2E83F4;
  color: white;
  width: 165px;
  height: 40px;
  border-radius: 10px;
}
.record-aside-list ul {
  width: 100%;
}
.record-aside-list ul.no-items-list {
  position: relative;
}
.record-aside-list li {
  display: block;
  margin: 8px 0 0 0;
  width: 100%;
}
.record-aside-list li:first-child {
  margin-top: 0;
}
.record-aside-list a {
  position: relative;
  display: block;
  padding: 0 100px 0 90px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #1C2026;
  overflow: hidden;
  text-decoration: none;
}
.record-aside-list a:hover {
  background: #2E83F4;
}
.record-aside-list a:hover .info {
  color: #fff;
}
.record-aside-list a.inactive {
  background: #0a0707;
}
.record-aside-list a .ico-person,
.record-aside-list a .ico-car,
.record-aside-list a .ico-animal,
.record-aside-list a .ico-light,
.record-aside-list a .ico-other {
  position: absolute;
  left: 0;
  top: 0;
  width: 70px;
  height: 100%;
  background-color: #ff003d;
  background-position: center center;
  background-repeat: no-repeat;
}
.record-aside-list a .ico-person {
  background-image: url(../img/ico-person.svg);
}
.record-aside-list a .ico-car {
  background-image: url(../img/ico-car.svg);
}
.record-aside-list a .ico-animal {
  background-image: url(../img/ico-animal.svg);
}
.record-aside-list a .ico-light {
  background-image: url(../img/ico-light.svg);
}
.record-aside-list a .ico-other {
  background-color: #405065;
  background-image: url(../img/ico-other.svg);
}
.record-aside-list a .date {
  display: inline-block;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.2em;
  color: #E2E2E2;
  vertical-align: middle;
}
.record-aside-list a .info {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 20px 0 0;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 40px;
  color: #A5B2C4;
}
.record-aside-list .visited {
  opacity: 0.2;
}
/* ---------
ANALYTICS
--------- */
.analytics {
  background: url(../img/analytics-bg.svg) right center no-repeat;
  background-size: auto 100%;
}
.analytics .item-desc {
  padding: 260px 0;
  max-width: 40%;
  text-align: left;
}
.analytics h1 {
  margin: 0 0 20px 0;
  font-size: 40px;
  font-size: 4rem;
  line-height: 1.2em;
  font-weight: 700;
  color: #E2E2E2;
}
.analytics p {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.4em;
  color: #A5B2C4;
}
/* ---------
MOBILE APP
--------- */
.mobileapp:after {
  content: "";
  display: table;
  clear: both;
}
/* ---------
MOBILE APP - LIST
--------- */
.mobileapp-content {
  float: left;
  padding-right: 230px;
  width: 66%;
}
.mobileapp-content h1 {
  display: block;
  margin: 0 0 35px 0;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.2em;
  font-weight: 700;
  color: #fff;
}
.mobileapp-content .p-empty {
  margin: 30px 0;
  padding: 0 0 0 40px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 26px;
  background: url(../img/ico-phone.svg) 10px center no-repeat;
}
.mobileapp-list {
  margin: 0 0 40px 0;
  border-top: 1px solid #2E353E;
}
.mobileapp-list ul {
  width: 100%;
}
.mobileapp-list li {
  width: 100%;
  border-bottom: 1px solid #2E353E;
}
.mobileapp-list .item-head {
  position: relative;
  padding: 10px 60px 10px 42px;
  width: 100%;
  min-height: 70px;
  line-height: 50px;
  background: url(../img/ico-phone-blue.svg) 10px center no-repeat;
  cursor: pointer;
}
.mobileapp-list .item-head:after {
  content: "";
  display: table;
  clear: both;
}
.mobileapp-list .item-head:hover h3 {
  color: #2E83F4;
}
.mobileapp-list .item-head h3 {
  display: inline-block;
  max-width: 100%;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.2em;
  color: #E2E2E2;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.mobileapp-list .item-head span {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 100%;
  background: url(../img/ico-edit.svg) center center no-repeat;
  text-indent: -9999px;
}
/* ---------
MOBILE APP - LIST - phone detail
--------- */
.mobileapp-item-content {
  padding: 10px 10px 24px 10px;
}
.mobileapp-item-content .item-name {
  margin: 0 -10px 16px;
}
.mobileapp-item-content .item-name input {
  padding: 12px 16px;
  width: 100%;
  height: auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 22px;
  color: #fff;
  background: #0F1114;
  outline: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.mobileapp-item-content .item-name input:focus {
  outline: 0;
  border-color: #2E83F4;
}
.mobileapp-item-content .item-hash {
  margin: 0 0 30px 0;
  padding: 12px 14px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #000;
}
.mobileapp-item-content .item-hash span {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.4em;
  color: #A5B2C4;
  overflow-wrap: break-word;
}
.mobileapp-item-content h4 {
  margin: 0 0 24px 0;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.2em;
  font-weight: 700;
  color: #E2E2E2;
}
.mobileapp-item-content .item-object-list {
  padding: 0 0 50px 0;
  font-size: 0px;
  font-size: 0rem;
  line-height: 0;
}
.mobileapp-item-content .item-object-list label {
  position: relative;
  display: inline-block;
  margin: 0 0 20px 0;
  padding: 0 20px 0 32px;
  width: 33.333%;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 22px;
  vertical-align: top;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.mobileapp-item-content .item-object-list label:hover {
  color: #2E83F4;
}
.mobileapp-item-content .item-object-list input {
  position: absolute;
  left: 2px;
  top: 2px;
  visibility: hidden;
}
.mobileapp-item-content .item-object-list input:checked + .like-checkbox {
  border-color: #2E83F4;
}
.mobileapp-item-content .item-object-list input:checked + .like-checkbox:after {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.mobileapp-item-content .item-object-list .like-checkbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #A5B2C4;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.mobileapp-item-content .item-object-list .like-checkbox:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../img/ico-checkbox.svg) center center no-repeat;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0.2);
  -moz-transform: scale(0.2);
  -ms-transform: scale(0.2);
  -o-transform: scale(0.2);
  transform: scale(0.2);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.mobileapp-item-content .item-footer:after {
  content: "";
  display: table;
  clear: both;
}
.mobileapp-item-content .item-footer .btn-remove {
  float: left;
  display: inline;
  background-color: transparent;
}
.mobileapp-item-content .item-footer .btn-save {
  float: right;
}
/* ---------
MOBILE APP - right aside
--------- */
.mobileapp-install {
  float: right;
  width: 34%;
}
.mobileapp-install h2 {
  display: block;
  margin: 0 0 35px 0;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.2em;
  font-weight: 700;
  color: #2E83F4;
}
.mobileapp-install-phone {
  float: left;
  width: 302px;
  height: auto;
}
.mobileapp-install-phone i {
  display: block;
  padding-bottom: 183.2%;
  width: 100%;
  height: auto;
  background: url(../img/img-mobile.png) 0 0 no-repeat;
  background-size: 100% auto;
}
.mobileapp-install-apps {
  float: right;
  text-align: center;
}
.mobileapp-install-apps .item {
  margin: 0 0 50px 0;
  width: 140px;
}
.mobileapp-install-apps .ico-gplay,
.mobileapp-install-apps .ico-ios {
  display: block;
  margin: 0 0 16px 0;
  width: 100%;
  height: 36px;
  background-position: center 0;
  background-repeat: no-repeat;
}
.mobileapp-install-apps .ico-gplay {
  background-image: url(../img/ico-android.svg);
}
.mobileapp-install-apps .ico-ios {
  background-image: url(../img/ico-apple.svg);
}
.mobileapp-install-apps .item-img {
  position: relative;
  margin: 0 auto 18px;
  width: 112px;
  height: 112px;
  background: #fff;
}
.mobileapp-install-apps .item-img svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.mobileapp-install-apps p {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5em;
}
.mobileapp-install-apps p a {
  font-weight: 700;
  color: #2E83F4;
}
/* ---------
SETTINGS
--------- */
.settings {
  margin: 0 auto 0;
  padding: 20px 24px 24px 24px;
  max-width: 1000px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #1C2026;
}
.settings h1 {
  display: block;
  margin: 0 0 35px 0;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.2em;
  font-weight: 700;
  color: #fff;
}
.settings .rules-tree {
  margin-bottom: 50px;
}
.settings .rules-tree li {
  margin-left: 30px;
  line-height: 20px;
}
.settings .rules-tree li ul,
.settings .rules-tree li table {
  margin-bottom: 20px;
}
/* ---------
SETTINGS - FORM
--------- */
.settings-form {
  margin: 0 -14px;
  font-size: 0px;
  font-size: 0rem;
  line-height: 0;
}
.settings-form h3 {
  margin: 0 0 14px 0;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.2em;
  font-weight: 700;
  color: #E2E2E2;
}
.settings-form .form-footer {
  padding: 0 14px;
  text-align: right;
}
.settings-form .form-footer .btn-gray {
  margin: 0 10px 0 0;
  min-width: 120px;
}
.settings-form .form-item-list,
.settings-form .alert-rule-list,
.settings-form .form-item-4 {
  display: inline-block;
  margin: 0 0 30px 0;
  padding: 0 14px;
  vertical-align: top;
}
.settings-form .form-item-4 {
  width: 25%;
}
.settings-form label {
  display: block;
  width: 100%;
}
.settings-form label span {
  display: block;
  margin: 0 0 6px 0;
  padding: 0 4px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.4em;
  color: #fff;
}
.settings-form label input,
.settings-form label select {
  padding: 8px 12px;
  width: 100%;
  height: auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #0F1114;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 18px;
  color: #fff;
  background: #0F1114;
  outline: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.settings-form label input:focus,
.settings-form label select:focus {
  outline: 0;
  border-color: #2E83F4;
}
.settings-form label input.invalid,
.settings-form label select.invalid {
  outline: 0;
  border-color: #ff003d;
}
.settings-form label select {
  background: url(../img/ico-select.svg) right 10px center no-repeat #0F1114;
}
.settings-form .form-item-list {
  display: block;
}
.settings-form .form-item-list .camerasList {
  margin-left: 32px;
  margin-bottom: 20px;
  padding-top: 8px;
}
.settings-form .form-item-list .camerasList label {
  margin-bottom: 10px;
}
.settings-form .form-item-checkbox,
.settings-form .form-item-radio {
  position: relative;
  margin: 0 0 20px 0;
  padding: 0 20px 0 32px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 22px;
  vertical-align: top;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.settings-form .form-item-checkbox:hover,
.settings-form .form-item-radio:hover {
  color: #2E83F4;
}
.settings-form .form-item-checkbox input,
.settings-form .form-item-radio input {
  position: absolute;
  left: 2px;
  top: 2px;
  padding: 0;
  width: auto;
  height: auto;
  visibility: hidden;
}
.settings-form .form-item-checkbox input:checked + .like-checkbox {
  border-color: #2E83F4;
}
.settings-form .form-item-checkbox input:checked + .like-checkbox:after {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.settings-form .form-item-checkbox .like-checkbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #A5B2C4;
  background: #0F1114;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.settings-form .form-item-checkbox .like-checkbox:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../img/ico-checkbox.svg) center center no-repeat;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0.2);
  -moz-transform: scale(0.2);
  -ms-transform: scale(0.2);
  -o-transform: scale(0.2);
  transform: scale(0.2);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.settings-form .form-item-radio {
  display: inline-block;
  width: auto;
}
.settings-form .form-item-radio input:checked + .like-radio {
  border-color: #2E83F4;
}
.settings-form .form-item-radio input:checked + .like-radio:after {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.settings-form .form-item-radio .like-radio {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #0F1114;
  border: 1px solid #A5B2C4;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.settings-form .form-item-radio .like-radio:after {
  content: '';
  position: absolute;
  left: 3px;
  top: 3px;
  width: 12px;
  height: 12px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background: #2E83F4;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(0.2);
  -moz-transform: scale(0.2);
  -ms-transform: scale(0.2);
  -o-transform: scale(0.2);
  transform: scale(0.2);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.settings-form h3 {
  margin-bottom: 30px;
}
.settings-form .alert-item-checkbox {
  margin-bottom: 30px;
  width: 190px;
  float: left;
}
.settings-form .alert-item-checkbox .form-item-checkbox {
  margin: 0;
}
.settings-form .alert-item-checkbox span.alert-name {
  display: block;
  width: 100%;
  font-size: 14px;
  margin-bottom: 20px;
}
.settings-form .alert-item-checkbox label {
  line-height: 10px;
  width: auto;
}
/* CHECKBOX - OPEN / CLOSE */
.checkbox-item-list {
  margin-bottom: 14px;
}
.checkbox-item-list .form-item-checkbox {
  margin-bottom: 0;
}
.checkbox-item-list .form-list-tree-toggle {
  position: relative;
  display: block;
  padding: 2px 0 2px  32px;
  font-size: 12px;
  line-height: 1.2em;
  color: #a5b2c4;
  opacity: 0.6;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.checkbox-item-list .form-list-tree-toggle:hover {
  color: #2E83F4;
  opacity: 1;
}
div.camera-checkbox-list div.checkbox-item-list {
  float: left;
  width: 190px;
}
div.camera-checkbox-list .clear {
  clear: both;
}
/* ---------
USER LIST - TABLE
--------- */
.torlin-table {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.torlin-table table {
  width: 100%;
}
.torlin-table tbody tr {
  background: #2E353E;
}
.torlin-table tbody tr:nth-child(even) {
  background: #1C2026;
}
.torlin-table th {
  padding: 4px 10px 4px 14px;
  border-bottom: 4px solid #0F1114;
  color: #fff;
  background: #1C2026;
  text-align: left;
}
.torlin-table .th-name {
  display: block;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.2em;
}
.torlin-table .th-input input {
  width: 165px;
  height: auto;
  padding: 4px 8px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #405065;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 18px;
  color: #fff;
  background: #0F1114;
  outline: 0;
}
.torlin-table .th-input input:focus {
  outline: 0;
  border-color: #2E83F4;
}
.torlin-table .th-sort {
  position: relative;
  padding-right: 20px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.torlin-table .th-sort .btn-sort-down,
.torlin-table .th-sort .btn-sort-up,
.torlin-table .th-sort .btn-sort {
  position: absolute;
  right: 0;
  top: 0;
  width: 12px;
  height: 100%;
  background: url(../img/ico-sort.svg) center center no-repeat;
  text-indent: -9999px;
}
.torlin-table .th-sort .btn-sort-up {
  background-image: url(../img/ico-sort-up.svg);
}
.torlin-table .th-sort .btn-sort-down {
  background-image: url(../img/ico-sort-down.svg);
}
.torlin-table td {
  padding: 8px 14px;
  border-left: 1px solid #0F1114;
  border-bottom: 1px solid #0F1114;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.2em;
  color: #fff;
}
.torlin-table td:first-child {
  border-left: 0;
}
.torlin-table .btn-edit,
.torlin-table .btn-delete {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  background: url(../img/ico-edit.svg) center center no-repeat;
  vertical-align: middle;
  text-indent: -9999px;
  overflow: hidden;
}
.torlin-table .btn-delete {
  background-image: url(../img/ico-remove.svg);
}
.torlin-table .btn-new {
  float: right;
}
.torlin-table div.pagination {
  margin-top: 50px;
  text-align: center;
  color: #A5B2C4;
  font-size: 18px;
}
.torlin-table div.pagination input,
.torlin-table div.pagination select {
  width: 62px;
  padding-left: 14px;
  height: 40px;
  border-radius: 4px;
  background: #0F1114;
  color: #E2E2E2;
  border: 1px solid #405065;
  margin-left: 16px;
  margin-right: 16px;
  outline: 0;
  text-align: center;
}
.torlin-table div.pagination input:focus,
.torlin-table div.pagination select:focus {
  border-color: #2E83F4;
}
.torlin-table div.pagination button {
  border-radius: 4px;
  padding: 4px;
  color: #E2E2E2;
  font-size: 30px;
  width: 62px;
  height: 40px;
}
.torlin-table div.pagination button.btn-next {
  background: url(../img/ico-arrow-right-lightgrey.svg) center center no-repeat #2E353E;
  margin-left: 32px;
}
.torlin-table div.pagination button.btn-prev {
  background: url(../img/ico-arrow-left-lightgrey.svg) center center no-repeat #2E353E;
  margin-right: 32px;
}
.torlin-table div.on-page {
  position: absolute;
  margin-top: -30px;
  right: 50px;
  line-height: 18px;
}
.torlin-table div.on-page select {
  margin-top: -10px;
  width: 80px;
  background: url('../img/ico-arrow-down-lightgrey.svg') 55px center no-repeat #0F1114;
}
div.react-select {
  font-size: 14px;
  border: 1px solid #405065;
  border-radius: 4px;
}
div.footfall div.footfall-header {
  display: flex;
}
div.footfall div.footfall-header div.filter {
  margin-left: 20px;
  margin-right: 30px;
  width: 400px;
}
div.footfall div.footfall-data {
  margin-top: 30px;
}
div.footfall div.footfall-data div.chart {
  height: 650px;
  min-width: 100%;
  margin-bottom: 50px;
}
div.footfall div.footfall-data-detail div.charts div.analytics-data {
  margin-top: 10px;
  margin-bottom: 80px;
}
div.footfall div.footfall-data-detail div.charts div.analytics-data div.chart {
  min-width: 100%;
  height: 650px;
  margin-bottom: 50px;
}
div.chart-overall {
  overflow: hidden;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
div.torlin-datepicker div.react-calendar {
  margin-top: 2px;
  background: #232323;
  border-radius: 4px;
  border: 1px solid #2E353E;
  padding-bottom: 30px;
}
div.torlin-datepicker div.react-calendar abbr {
  border-bottom: none;
  text-decoration: none;
}
div.torlin-datepicker div.react-calendar abbr[title] {
  font-size: 14px;
}
div.torlin-datepicker div.react-calendar button {
  border-radius: 0;
  height: 40px;
  line-height: 0;
  padding: 0;
  text-decoration: none;
}
div.torlin-datepicker div.react-calendar button.react-calendar__tile--now {
  font-weight: bold;
  background: transparent;
}
div.torlin-datepicker div.react-calendar button.react-calendar__tile--active {
  background: #2E83F4;
}
div.torlin-datepicker div.react-calendar button.react-calendar__tile--rangeStart {
  background: #215fb0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
div.torlin-datepicker div.react-calendar button.react-calendar__tile--rangeEnd {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: #215fb0;
}
div.torlin-datepicker div.react-calendar button.react-calendar__tile:disabled {
  background: #352424;
  color: #414141;
}
div.torlin-datepicker div.react-calendar button.react-calendar__navigation__label,
div.torlin-datepicker div.react-calendar button.react-calendar__navigation__arrow {
  background: transparent;
}
div.torlin-datepicker div.react-daterange-picker__wrapper {
  width: 100%;
  padding: 0 10px 0 10px;
  height: auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #fff;
  border: 1px solid #2E353E;
  background: #0F1114;
  outline: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
div.torlin-datepicker div.react-daterange-picker__wrapper:focus {
  outline: 0;
  border-color: #2E83F4;
}
div.torlin-datepicker div.react-daterange-picker__wrapper input {
  color: white;
}
div.torlin-datepicker div.react-daterange-picker__wrapper input:focus {
  outline: 0;
  padding-bottom: 2px;
}
div.torlin-datepicker div.react-daterange-picker__wrapper svg {
  stroke: white;
}
div.torlin-datepicker div.react-daterange-picker__wrapper button {
  background: transparent;
}
div.torlin-datepicker div.react-daterange-picker__wrapper span.react-daterange-picker__range-divider {
  padding-right: 10px;
}
div.RoiSettingFilter div {
  margin-top: 10px;
}
div.RoiSettingFilter div div {
  margin-top: 0;
}
div.RoiSettingFilter div.roi-dimension {
  padding: 10px;
  max-width: 100%;
  max-height: 800px;
  overflow: auto;
}
div.RoiSettingFilter button {
  margin-right: 10px;
}
div.RoiSettingFilter button:disabled {
  background: #414141;
  color: grey;
  cursor: default;
}
div.codex-editor {
  border: 1px solid #2e353e;
  border-radius: 5px;
}
div.codex-editor:focus-within {
  border-color: #2E83F4;
}
div.codex-editor div.ce-block__content,
div.codex-editor div.ce-toolbar__content {
  max-width: 1400px;
}
div.codex-editor div.ce-block__content svg,
div.codex-editor div.ce-toolbar__content svg {
  fill: #2E83F4;
}
div.codex-editor h1,
div.mails h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
}
div.codex-editor h2,
div.mails h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}
div.codex-editor h3,
div.mails h3 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}
div.codex-editor p,
div.mails p {
  margin-top: 5px;
  line-height: 2rem;
}
div.codex-editor br,
div.mails br {
  height: 2px;
}
div.codex-editor figure,
div.mails figure {
  padding: 10px;
  margin: 20px 0 20px 0;
  border-radius: 5px;
}
div.codex-editor figure.image-block--with-border,
div.mails figure.image-block--with-border {
  border: 1px solid #414141;
}
div.codex-editor figure figcaption,
div.mails figure figcaption {
  font-style: italic;
  border-top: 1px solid #414141;
  padding-top: 10px;
  text-align: center;
}
div.codex-editor figure figcaption::before,
div.mails figure figcaption::before {
  content: 'Obr: ';
}
div.new-mail-form div.title input {
  width: 1000px;
}
div.new-mail-form div.published,
div.new-mail-form div.title {
  margin-bottom: 1rem;
}
div.new-mail-form div.published input,
div.new-mail-form div.title input {
  margin-left: 1rem;
}
div.new-mail-form div.published input::-webkit-calendar-picker-indicator,
div.new-mail-form div.title input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
div.new-mail-form div.edit-mail-buttons {
  margin-top: 1rem;
}
.row-pointer {
  cursor: pointer;
}
div.MuiDataGrid-row.Mui-even div.MuiDataGrid-cell {
  background: #232323;
}
@media screen and (max-width: 1670px) {
  /* ---------
  RECORD - ASIDE - NAV / ACTION
  --------- */
  .record-aside-action {
    padding: 0 40px;
  }
  .record-aside-action .btn-prev,
  .record-aside-action .btn-next {
    width: 40px;
    height: 100%;
  }
  .record-aside-action .btn-select-date {
    position: relative;
    padding: 6px 20px 0 0;
    height: 50px;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.2em;
  }
  .record-aside-action .btn-select-date strong {
    display: block;
    margin: 0 0 4px 0;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.2em;
  }
  .record-aside-action .btn-select-date .ico-edit {
    position: absolute;
    top: 50%;
    right: 8px;
    margin: -8px 0 0 0;
    width: 12px;
    height: 12px;
    background-size: 12px auto;
  }
  /* ---------
  RECORD - ASIDE - SETTINGS
  --------- */
  .record-aside-settings-content {
    padding: 10px;
  }
  .record-aside-settings-content .item-checkbox {
    margin: 6px auto 20px auto;
  }
  .record-aside-settings-content .row-from,
  .record-aside-settings-content .row-to {
    margin: 0 0 6px 0;
  }
  .record-aside-settings-content .row-from span,
  .record-aside-settings-content .row-to span {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 32px;
  }
  .record-aside-settings-content .row-from .row-item-date,
  .record-aside-settings-content .row-to .row-item-date,
  .record-aside-settings-content .row-from .row-item-time,
  .record-aside-settings-content .row-to .row-item-time {
    height: 32px;
  }
  .record-aside-settings-content .row-from .row-item-date input,
  .record-aside-settings-content .row-to .row-item-date input,
  .record-aside-settings-content .row-from .row-item-time input,
  .record-aside-settings-content .row-to .row-item-time input {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 32px;
  }
}
@media screen and (max-width: 1400px) {
  /* ---------
  ANALYTICS
  --------- */
  .analytics .item-desc {
    padding: 200px 0;
  }
  .analytics h1 {
    font-size: 32px;
    font-size: 3.2rem;
    line-height: 1.2em;
  }
  .analytics p {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.4em;
  }
  /* ---------
  MOBILE APP - LIST
  --------- */
  .mobileapp-content {
    width: 60%;
    padding-right: 100px;
  }
  /* ---------
  MOBILE APP - right aside
  --------- */
  .mobileapp-install {
    width: 40%;
  }
}
@media screen and (max-width: 1280px) {
  /* ---------
  UI
  --------- */
  select {
    padding: 0 24px 0 8px;
    width: 140px;
    height: 26px;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 26px;
    background-position: right 6px center;
  }
  .item-checkbox {
    padding: 0 0 0 22px;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 18px;
  }
  .item-checkbox .like-checkbox {
    width: 18px;
    height: 18px;
  }
  /* ---------
  BTNs
  --------- */
  .btn-blue,
  .btn-save,
  .btn-blue-small,
  .btn-download {
    height: 40px;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 40px;
  }
  .btn-blue-small {
    padding: 0 14px;
    height: 32px;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 32px;
  }
  .btn-save,
  .btn-download {
    padding: 0 14px;
    height: 32px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 32px;
    /*.ico-ios,
    .ico-gplay{
      position:relative;
      top:-2px;
      display:inline-block;
      .size(36px, 20px);
      background-position:0 center;
      background-repeat:no-repeat;
      background-size:auto 20px;
      vertical-align:middle;
    }
    .ico-ios{
      background-image:url(../img/ico-apple.svg);
    }
    .ico-gplay{
      background-image:url(../img/ico-google-play.svg);
    }*/
  }
  .btn-save {
    min-width: 100px;
  }
  .btn-gray {
    min-width: 100px;
    height: 32px;
    padding: 0 14px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 32px;
  }
  /*.btn-red_{
    display:inline-block;
    min-width:170px;
    height:40px;
    padding:0 18px;
    .border-radius(10px);
    border:1px solid @red;
    .font-size(18, 40px);
    font-weight:700;
    color:#E2E2E2;
    background:@red;
    vertical-align:top;
    text-decoration:none;
    text-align:center;
    cursor:pointer;
    outline:0;

    &:focus{
      outline:none;
    }
  }*/
  .btn-detail,
  .btn-fav {
    margin: 0 0 0 4px;
    width: 26px;
    height: 26px;
    background-size: 12px auto;
  }
  .btn-more {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 22px;
  }
  .btn-more i {
    width: 16px;
    height: 22px;
    background-size: 16px auto;
  }
  .btn-remove {
    padding: 0 0 0 22px;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 32px;
  }
  .btn-remove:before {
    width: 16px;
    height: 32px;
    background-size: 16px auto;
  }
  /* ---------
  TABS
  --------- */
  .react-tabs .react-tabs__tab-list {
    margin: 0 0 20px 0;
  }
  .react-tabs .react-tabs__tab {
    padding: 10px 16px;
    border: 0;
    font-size: 14px;
  }
  /* ---------
  BREADCRUMBS
  --------- */
  .breadcrumbs {
    margin: 0 0 16px 0;
    padding: 8px 10px;
  }
  .breadcrumbs li:before {
    font-size: 10px;
    font-size: 1rem;
    line-height: 18px;
  }
  .breadcrumbs li a,
  .breadcrumbs li span {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 18px;
  }
  /* ---------
  LAYOUT
  --------- */
  .in {
    padding-left: 30px;
    padding-right: 30px;
  }
  /* ---------
  SECTION
  --------- */
  section {
    padding: 10px 0 0 0;
  }
  /* ---------
  DASHBOARD
  --------- */
  .camera-item-head {
    padding: 8px 60px 10px 10px;
    line-height: 32px;
  }
  .camera-item-head h2 {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.2em;
  }
  .camera-item-head .ico-arrow {
    right: 8px;
    margin-top: -16px;
    width: 32px;
    height: 32px;
    background-size: 14px auto;
  }
  /* ---------
  CAMERA LIST
  --------- */
  .camera-list-4 {
    padding: 8px 10px;
  }
  /* ---------
  CAMERA ITEM
  --------- */
  .camera-head {
    padding: 0 80px 6px 0;
  }
  .camera-head h3 a {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 26px;
  }
  /* ---------
  RECORD - ASIDE
  --------- */
  .record-aside {
    padding-left: 10px;
  }
  /* ---------
  RECORD - ASIDE - SETTINGS
  --------- */
  .record-aside-settings-content .row-from,
  .record-aside-settings-content .row-to {
    margin: 0 0 14px 0;
  }
  .record-aside-settings-content .row-from span,
  .record-aside-settings-content .row-to span {
    display: block;
    float: none;
    padding: 0 0 6px 0;
    width: 100%;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.2em;
  }
  .record-aside-settings-content .row-from .row-item-date,
  .record-aside-settings-content .row-to .row-item-date {
    padding-left: 0;
    width: 60%;
  }
  .record-aside-settings-content .row-from .row-item-time,
  .record-aside-settings-content .row-to .row-item-time {
    width: 40%;
  }
  .record-aside-settings-content .MuiFormControl-root {
    width: 100%;
  }
  /* ---------
  RECORD - PLAYER
  --------- */
  .record-head {
    position: relative;
    padding: 0 230px 8px 12px;
  }
  .record-head h2 {
    margin: 0 4px 0 0;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 26px;
  }
  .record-head select {
    margin-right: 20px;
  }
  /* ---------
  RECORD - ASIDE - LIVE switch
  --------- */
  /*.record-aside-live-info{

  }*/
  /* ---------
  RECORD - ASIDE - NAV / ACTION
  --------- */
  .record-aside-action {
    margin: 0 0 4px 0;
  }
  /* ---------
  RECORD - ASIDE - SETTINGS
  --------- */
  .record-aside-settings {
    margin: 0 0 4px 0;
  }
  /* ---------
  RECORD - ASIDE - LIST
  --------- */
  .record-aside-list {
    width: 100%;
  }
  .record-aside-list div.no-items {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, #000000 70%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 40px 30px 50px 30px;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
  }
  .record-aside-list div.no-items strong {
    font-weight: lighter;
    color: #2E83F4;
  }
  .record-aside-list div.no-items button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    background: #2E83F4;
    color: white;
    width: 165px;
    height: 40px;
    border-radius: 10px;
  }
  .record-aside-list li {
    margin: 4px 0 0 0;
  }
  .record-aside-list li:first-child {
    margin-top: 0;
  }
  .record-aside-list a {
    padding: 0 60px 0 50px;
    width: 100%;
    height: 32px;
    line-height: 32px;
  }
  .record-aside-list a .ico-person,
  .record-aside-list a .ico-car,
  .record-aside-list a .ico-animal,
  .record-aside-list a .ico-other {
    width: 40px;
    height: 100%;
    background-size: auto 16px;
  }
  .record-aside-list a .ico-other {
    background-size: auto 14px;
  }
  .record-aside-list a .date {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.2em;
  }
  .record-aside-list a .info {
    padding: 0 8px 0 0;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 32px;
  }
  /* ---------
  ANALYTICS
  --------- */
  .analytics .item-desc {
    padding: 180px 0;
  }
  .analytics h1 {
    font-size: 28px;
    font-size: 2.8rem;
    line-height: 1.2em;
  }
  .analytics p {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.4em;
  }
  /* ---------
  MOBILE APP - LIST
  --------- */
  .mobileapp-content {
    padding-right: 60px;
  }
  .mobileapp-content h1 {
    margin: 0 0 20px 0;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.2em;
  }
  .mobileapp-content .p-empty {
    margin: 20px 0;
    padding: 0 0 0 32px;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 26px;
    background-position: 6px center;
  }
  .mobileapp-list {
    margin: 0 0 20px 0;
  }
  .mobileapp-list .item-head {
    padding: 6px 40px 6px 40px;
    min-height: 54px;
    line-height: 40px;
    background-position: 8px center;
  }
  .mobileapp-list .item-head h3 {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.2em;
  }
  .mobileapp-list .item-head span {
    width: 32px;
    height: 100%;
    background-size: 12px auto;
  }
  /* ---------
  MOBILE APP - LIST - phone detail
  --------- */
  .mobileapp-item-content {
    padding: 6px 6px 10px 6px;
  }
  .mobileapp-item-content .item-name {
    margin: 0 -6px 10px;
  }
  .mobileapp-item-content .item-name input {
    padding: 8px 10px;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 26px;
  }
  .mobileapp-item-content .item-hash {
    margin: 0 0 20px 0;
    padding: 8px 10px;
  }
  .mobileapp-item-content .item-hash span {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.4em;
  }
  .mobileapp-item-content h4 {
    margin: 0 0 10px 0;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.2em;
  }
  .mobileapp-item-content .item-object-list {
    padding: 0 0 30px 0;
  }
  .mobileapp-item-content .item-object-list label {
    margin: 0 0 10px 0;
    padding: 0 10px 0 26px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 20px;
  }
  .mobileapp-item-content .item-object-list .like-checkbox {
    width: 18px;
    height: 18px;
  }
  /* ---------
  MOBILE APP - right aside
  --------- */
  .mobileapp-install h2 {
    margin: 0 0 20px 0;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.2em;
  }
  .mobileapp-install-phone {
    width: 60%;
    max-width: 300px;
  }
  .mobileapp-install-apps {
    padding-left: 20px;
    width: 40%;
  }
  .mobileapp-install-apps .item {
    margin: 0 0 30px 0;
    width: 120px;
  }
  .mobileapp-install-apps .ico-gplay,
  .mobileapp-install-apps .ico-ios {
    display: block;
    margin: 0 0 10px 0;
    width: 100%;
    height: 26px;
    background-size: auto 100%;
  }
  .mobileapp-install-apps .item-img {
    margin: 0 auto 10px;
  }
  .mobileapp-install-apps p {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5em;
  }
  /* ---------
  SETTINGS - FORM
  --------- */
  .settings-form h3 {
    margin: 0 0 10px 0;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.2em;
  }
  .settings-form .form-footer {
    padding: 0 14px;
    text-align: right;
  }
  .settings-form .form-footer .btn-gray {
    margin: 0 10px 0 0;
    min-width: 100px;
  }
}
@media screen and (max-width: 1140px) {
  .btn-download-ico {
    width: 32px;
    height: 32px;
  }
  /* ---------
  RECORD - CLIP
  --------- */
  .record-clip {
    padding: 10px;
  }
  .record-clip-editor {
    min-height: 32px;
  }
  .record-clip-editor .clip-start i {
    margin-left: 8px;
  }
  .record-clip-editor .clip-end i {
    margin-right: 8px;
  }
  .record-clip-editor label {
    padding: 0 12px;
    height: 32px;
    font-size: 14px;
    line-height: 32px;
  }
  .record-clip-editor label i {
    width: 12px;
    height: 12px;
    background-size: 12px auto;
  }
  .record-clip-editor input {
    padding: 0 10px;
    width: 90px;
    height: 32px;
    border: 0;
    font-size: 14px;
    line-height: 32px;
  }
  .record-clip-settings {
    padding-left: 10px;
  }
  .record-clip-settings-params {
    min-height: 32px;
  }
  .record-clip-settings-params p {
    margin: 0 20px 0 0;
    font-size: 12px;
  }
  /* ---------
  RECORD - ASIDE - LIST
  --------- */
  .record-aside-list div.no-items {
    padding: 20px 16px 40px 16px;
    font-size: 16px;
    line-height: 1.4em;
  }
  /* ---------
  USER LIST - TABLE
  --------- */
  .users-list th {
    padding: 2px 6px 2px 10px;
    border-bottom: 2px solid #0F1114;
  }
  .users-list .th-name {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.2em;
  }
  .users-list .th-input input {
    width: 126px;
    padding: 4px 6px;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 18px;
  }
  .users-list .th-sort {
    padding-right: 18px;
  }
  .users-list td {
    padding: 6px 10px;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.2em;
  }
  .users-list .btn-edit {
    width: 20px;
    height: 20px;
    background-size: 12px auto;
  }
}
@media screen and (max-width: 1020px) {
  /* ---------
  BREADCRUMBS
  --------- */
  .breadcrumbs {
    margin: 0 0 16px 0;
    padding: 6px 0;
  }
  .breadcrumbs li:before {
    font-size: 10px;
    font-size: 1rem;
    line-height: 16px;
  }
  .breadcrumbs li a,
  .breadcrumbs li span {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 16px;
  }
  /* ---------
  SWITCH - LIVE Vs. RECORD
  --------- */
  .camdetail-switch {
    padding: 3px;
    height: 26px;
  }
  .camdetail-switch button {
    padding: 0 10px;
    height: 20px;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 20px;
  }
  /* ---------
  CAMERA LIST
  --------- */
  .camera-list-4 .camera-list-item {
    width: 50%;
  }
  /* ---------
  RECORD - PLAYER
  --------- */
  .record-head {
    padding: 0 160px 8px 0px;
  }
  .record-head h2 {
    margin: 0 8px 0 0;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 26px;
  }
  .record-head select {
    margin-right: 10px;
  }
  /* ---------
  ANALYTICS
  --------- */
  .analytics .item-desc {
    padding: 100px 0;
    max-width: 50%;
  }
  .analytics h1 {
    font-size: 22px;
    font-size: 2.2rem;
    line-height: 1.2em;
  }
  .analytics p {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.4em;
  }
  /* ---------
  SETTINGS - FORM
  --------- */
  .settings-form .form-item-4 {
    width: 33.3333%;
  }
}
@media screen and (max-width: 940px) {
  /* ---------
  RECORD - ASIDE - SETTINGS
  --------- */
  .record-aside-settings-content .row-from,
  .record-aside-settings-content .row-to {
    margin: 0 0 14px 0;
  }
  .record-aside-settings-content .row-from span,
  .record-aside-settings-content .row-to span {
    display: block;
    float: none;
    padding: 0 0 6px 0;
    width: 100%;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.2em;
  }
  .record-aside-settings-content .row-from .row-item-date,
  .record-aside-settings-content .row-to .row-item-date,
  .record-aside-settings-content .row-from .row-item-time,
  .record-aside-settings-content .row-to .row-item-time {
    float: none;
    padding: 0;
    width: 100%;
  }
  .record-aside-settings-content .row-from .row-item-date input,
  .record-aside-settings-content .row-to .row-item-date input,
  .record-aside-settings-content .row-from .row-item-time input,
  .record-aside-settings-content .row-to .row-item-time input {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 28px;
  }
  .record-aside-settings-content .row-from .row-item-date,
  .record-aside-settings-content .row-to .row-item-date {
    margin: 0 0 6px 0;
  }
  .record-aside-settings-content .MuiFormControl-root {
    width: 100%;
  }
  /* ---------
  MOBILE APP - LIST
  --------- */
  .mobileapp-content {
    float: none;
    padding-right: 0;
    width: 100%;
  }
  /* ---------
  MOBILE APP - right aside
  --------- */
  .mobileapp-install {
    float: none;
    padding: 40px 0 0 0;
    width: 100%;
    text-align: center;
  }
  .mobileapp-install-phone {
    display: none;
  }
  .mobileapp-install-apps {
    float: none;
    padding: 0;
    width: 100%;
    text-align: center;
  }
  .mobileapp-install-apps .item {
    display: inline-block;
    width: 140px;
    vertical-align: top;
  }
  /* ---------
  USER LIST - TABLE
  --------- */
  .users-list table {
    min-width: 1000px;
  }
}
@media screen and (max-width: 900px) {
  /* ---------
  RECORD - PLAYER
  --------- */
  .record-content {
    float: none;
    margin: 0 0 20px 0;
    width: 100%;
  }
  /* ---------
  RECORD - ASIDE
  --------- */
  .record-aside {
    position: relative;
    right: 0;
    top: 0;
    padding-left: 0;
    width: 100%;
    height: auto;
  }
  .record-aside-settings-content .row-from,
  .record-aside-settings-content .row-to {
    margin: 0 0 10px 0;
  }
  .record-aside-settings-content .row-from span,
  .record-aside-settings-content .row-to span {
    float: left;
    padding: 0;
    width: 14%;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 32px;
  }
  .record-aside-settings-content .row-from .row-item-date,
  .record-aside-settings-content .row-to .row-item-date,
  .record-aside-settings-content .row-from .row-item-time,
  .record-aside-settings-content .row-to .row-item-time {
    float: left;
    padding-left: 10px;
    height: 32px;
  }
  .record-aside-settings-content .row-from .row-item-date,
  .record-aside-settings-content .row-to .row-item-date {
    margin: 0;
    width: 52%;
  }
  .record-aside-settings-content .row-from .row-item-time,
  .record-aside-settings-content .row-to .row-item-time {
    width: 34%;
  }
  .record-player-error p {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.4em;
  }
}
@media screen and (max-width: 700px) {
  /* ---------
  BTNs
  --------- */
  .btn-more {
    margin: 0 0 10px 0;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 22px;
  }
  /* ---------
  TABS
  --------- */
  .react-tabs .react-tabs__tab-list {
    margin: 0 0 10px 0;
  }
  .react-tabs .react-tabs__tab {
    padding: 8px 10px;
    font-size: 12px;
  }
  /* ---------
  LAYOUT
  --------- */
  .in {
    padding-left: 20px;
    padding-right: 20px;
  }
  /* ---------
  FOOTER
  --------- */
  .support {
    right: 20px;
  }
  .support-btn {
    padding: 0 10px 0 30px;
    height: 30px;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 30px;
    background-position: 8px center;
    background-size: auto 12px;
  }
  .support-content {
    padding: 20px 10px;
  }
  .support-content h3 {
    margin: 0 0 20px 0;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.2em;
  }
  .support-type-phone,
  .support-type-email {
    padding: 30px 20px 0;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.2em;
    background-size: 20px auto;
  }
  /* ---------
  DASHBOARD
  --------- */
  .camera-item-head {
    padding: 6px 50px 8px 8px;
    line-height: 28px;
  }
  .camera-item-head h2 {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.2em;
  }
  .camera-item-head .ico-arrow {
    right: 8px;
    margin-top: -14px;
    width: 28px;
    height: 28px;
  }
  /* ---------
  CAMERA LIST
  --------- */
  .camera-list-item {
    margin: 0 0 20px 0;
  }
  /* ---------
  CAMERA ITEM
  --------- */
  .camera-head h3 a {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 26px;
  }
  /* ---------
  RECORD - PLAYER
  --------- */
  .record-head h2 {
    display: block;
    margin: 0 0 4px 0;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.2em;
  }
  .record-head-item {
    margin: 0 14px 0 0;
    width: 140px;
  }
  .record-head-item select {
    margin: 0;
    width: 100%;
  }
  .record-head-action {
    padding-top: 18px;
  }
  /* ---------
  RECORD - CLIP
  --------- */
  .record-clip {
    display: block;
  }
  .record-clip-editor {
    margin: 0 0 8px 0;
    width: 100%;
    /*.clip-start{
      border-right:0;
      border-bottom:1px solid #1C2026;
    }
    .clip-end{
      label{
        order:1;
        .border-radius(0);
      }
      input{
        order:2;
      }
    }
    label{
      width:100px;
    }*/
  }
  .record-clip-settings {
    width: 100%;
    padding: 0;
  }
  /* ---------
  ANALYTICS
  --------- */
  .analytics {
    background: none;
  }
  .analytics .item-desc {
    padding: 40px 0;
    max-width: 100%;
    text-align: center;
  }
  /* ---------
  SETTINGS
  --------- */
  .settings {
    padding: 14px;
  }
  /* ---------
  SETTINGS - FORM
  --------- */
  .settings-form {
    margin: 0 -8px;
  }
  .settings-form h3 {
    margin: 0 0 10px 0;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.2em;
  }
  .settings-form .form-footer {
    padding: 0 8px;
  }
  .settings-form .form-item-list,
  .settings-form .form-item-4 {
    margin: 0 0 20px 0;
    padding: 0 8px;
  }
  .settings-form .form-item-4 {
    width: 50%;
  }
  .settings-form label span {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.4em;
  }
  .settings-form label input,
  .settings-form label select {
    padding: 6px 10px;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 18px;
  }
  .settings-form .form-item-checkbox,
  .settings-form .form-item-radio {
    margin: 0 0 14px 0;
    padding: 0 10px 0 28px;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 20px;
  }
  .settings-form .form-item-checkbox .like-checkbox {
    width: 18px;
    height: 18px;
  }
  .settings-form .form-item-radio .like-radio {
    width: 18px;
    height: 18px;
  }
  .settings-form .form-item-radio .like-radio:after {
    width: 10px;
    height: 10px;
  }
}
@media screen and (max-width: 560px) {
  /* ---------
  SWITCH - LIVE Vs. RECORD
  --------- */
  .camdetail-switch {
    display: block;
    margin-bottom: 4px;
  }
  /* ---------
  RECORD - PLAYER
  --------- */
  .record-head {
    padding: 0 120px 0 0;
  }
  .record-head h2 {
    float: left;
    margin: 0;
    width: 70px;
    line-height: 26px;
  }
  .record-head-item {
    margin: 0 0 6px 0;
    width: 220px;
  }
  .record-head-item:after {
    content: "";
    display: table;
    clear: both;
  }
  .record-head-item select {
    float: left;
    width: 150px;
  }
  .record-head-action {
    padding-top: 0;
  }
  /* ---------
  MOBILE APP - right aside
  --------- */
  .mobileapp-install {
    padding: 20px 0 0 0;
  }
  .mobileapp-install h2 {
    margin: 0 0 10px 0;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.2em;
  }
  /* ---------
  CAMERA LIST
  --------- */
  .camera-list-4 .camera-list-item {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .btn-download-ico {
    width: 28px;
    height: 28px;
    background-size: 10px auto;
  }
  /* ---------
  LAYOUT
  --------- */
  .in {
    padding-left: 10px;
    padding-right: 10px;
  }
  /* ---------
  RECORD - CLIP
  --------- */
  .record-clip {
    padding: 6px;
  }
  .record-clip-editor {
    min-height: 28px;
  }
  .record-clip-editor .clip-start i {
    margin-left: 4px;
  }
  .record-clip-editor .clip-end i {
    margin-right: 4px;
  }
  .record-clip-editor label {
    padding: 0;
    width: 76px;
    height: 28px;
    font-size: 12px;
    line-height: 28px;
    text-align: center;
  }
  .record-clip-editor label i {
    width: 10px;
    height: 10px;
    background-size: 10px auto;
  }
  .record-clip-editor input {
    padding: 0 6px;
    width: 80px;
    height: 28px;
    font-size: 12px;
    line-height: 28px;
  }
  .record-clip-settings-params {
    min-height: 28px;
  }
  /* ---------
  MOBILE APP - LIST
  --------- */
  .mobileapp-content h1 {
    margin: 0 0 10px 0;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.2em;
  }
  .mobileapp-list {
    margin: 0 0 10px 0;
  }
  .mobileapp-list .item-head {
    padding: 4px 30px;
    min-height: 46px;
    line-height: 38px;
    background-position: 8px center;
    background-size: 12px auto;
  }
  .mobileapp-list .item-head h3 {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.2em;
  }
  .mobileapp-list .item-head span {
    width: 24px;
    height: 100%;
  }
  /* ---------
  MOBILE APP - LIST - phone detail
  --------- */
  .mobileapp-item-content .item-name input {
    padding: 6px 10px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 18px;
  }
  .mobileapp-item-content .item-hash span {
    font-size: 10px;
    font-size: 1rem;
    line-height: 1.4em;
  }
  .mobileapp-item-content .item-object-list label {
    width: 50%;
  }
  /* ---------
  SETTINGS
  --------- */
  .settings {
    padding: 10px;
  }
  /* ---------
  SETTINGS - FORM
  --------- */
  .settings-form .form-item-4 {
    width: 100%;
  }
}
@media screen and (max-width: 370px) {
  /* ---------
  TABS
  --------- */
  .react-tabs .react-tabs__tab {
    padding: 8px 8px;
    font-size: 11px;
  }
  /* ---------
  POPUP
  --------- */
  .popup-overlay {
    padding: 14px;
  }
  .popup-overlay .header {
    margin: 0 0 6px 0;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.2em;
  }
  .popup-overlay .content {
    margin: 0 0 20px 0;
    font-size: 14px;
  }
  .popup-overlay .btn-cancel,
  .popup-overlay .btn-danger {
    margin: 0 4px;
    min-width: 100px;
    padding: 0 10px;
    height: 34px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 32px;
  }
  /* ---------
  SWITCH - LIVE Vs. RECORD
  --------- */
  .camdetail-switch {
    margin-bottom: 24px;
  }
  /* ---------
  RECORD - PLAYER
  --------- */
  .record-head {
    min-height: 100px;
  }
  .record-head h2 {
    float: none;
    margin: 0 0 4px 0;
    width: 100px;
    line-height: 1.2em;
  }
  .record-head-item {
    width: 100%;
  }
  .record-head-item select {
    float: none;
    width: 100%;
  }
  .record-head-action {
    padding-top: 18px;
  }
  /* ---------
  RECORD - CLIP
  --------- */
  .record-clip {
    display: block;
  }
  .record-clip-editor {
    margin: 0 0 12px 0;
  }
  .record-clip-editor .clip-start,
  .record-clip-editor .clip-end {
    width: 50%;
  }
  .record-clip-editor label {
    width: 70px;
  }
  .record-clip-editor input {
    width: 100%;
  }
  .record-clip-settings {
    text-align: left;
  }
  /* ---------
  MOBILE APP - LIST - phone detail
  --------- */
  .mobileapp-item-content .item-object-list label {
    width: 100%;
  }
  /* ---------
  FOOTER
  --------- */
  .support {
    right: 10px;
  }
  .support-content {
    padding: 14px 10px;
  }
  .support-content h3 {
    margin: 0 0 16px 0;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.2em;
  }
  .support-type-phone,
  .support-type-email {
    padding: 30px 10px 0;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.2em;
  }
  /* ---------
  ANALYTICS
  --------- */
  .analytics h1 {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.2em;
  }
}
@media screen and (max-width: 1091px) {
  .torlin-table div.pagination {
    font-size: 14px;
  }
}
.torlin-table div.pagination button {
  width: 52px;
}
.torlin-table div.pagination button.btn-prev {
  margin-right: 20px;
}
.torlin-table div.pagination button.btn-next {
  margin-left: 20px;
}
.torlin-table div.pagination input {
  padding-left: 0;
  width: 50px;
}
.torlin-table div.pagination select {
  width: 70px;
  background-position: 45px center;
}
@media screen and (max-width: 870px) {
  .torlin-table div.pagination div.on-page {
    position: static;
    margin-top: 40px;
  }
}
@media screen and (max-width: 820px) {
  .torlin-table div.pagination {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 800px) {
  div.footfall div.footfall-header {
    display: block;
  }
  div.footfall div.footfall-header div.filter {
    margin: 20px 0 20px 0;
    width: 100%;
  }
}
