// MEDIA QUERIES
// ---------

@media screen and (max-width: 1670px){

  /* ---------
  RECORD - ASIDE - NAV / ACTION
  --------- */
  .record-aside-action{
    padding:0 40px;

    .btn-prev,
    .btn-next{
      .size(40px, 100%);
    }
    .btn-select-date{
      position:relative;
      padding:6px 20px 0 0;
      height:50px;
      .font-size(12, 1.2em);

      strong{
        display:block;
        margin:0 0 4px 0;
        .font-size(16, 1.2em);
      }
      .ico-edit{
        position:absolute;
        top:50%;
        right:8px;
        margin:-8px 0 0 0;
        .sq(12px);
        background-size:12px auto;
      }
    }
  }

  /* ---------
  RECORD - ASIDE - SETTINGS
  --------- */
  .record-aside-settings-content{
    padding:10px;

    .item-checkbox{
      margin:6px auto 20px auto;
    }
    .row-from,
    .row-to{
      margin:0 0 6px 0;

      span{
        .font-size(14, 32px);
      }
      .row-item-date,
      .row-item-time{
        height:32px;

        input{
          .font-size(12, 32px);
        }
      }
    }
  }

}

@media screen and (max-width: 1400px){

  /* ---------
  ANALYTICS
  --------- */
  .analytics{
    .item-desc{
      padding:200px 0;
    }
    h1{
      .font-size(32, 1.2em);
    }
    p{
      .font-size(16, 1.4em);
    }
  }


  /* ---------
  MOBILE APP - LIST
  --------- */
  .mobileapp-content{
    width:60%;
    padding-right:100px;
  }

  /* ---------
  MOBILE APP - right aside
  --------- */
  .mobileapp-install{
    width:40%;
  }



}


@media screen and (max-width: 1280px){

  /* ---------
  UI
  --------- */
  select{
    padding:0 24px 0 8px;
    .size(140px, 26px);
    .font-size(12, 26px);
    background-position:right 6px center;
  }
  .item-checkbox{
    padding:0 0 0 22px;
    .font-size(12, 18px);

    .like-checkbox{
      .sq(18px);
    }
  }



  /* ---------
  BTNs
  --------- */
  .btn-blue,
  .btn-save,
  .btn-blue-small,
  .btn-download{
    height:40px;
    .font-size(16, 40px);
  }
  .btn-blue-small{
    padding:0 14px;
    height:32px;
    .font-size(12, 32px);
  }
  .btn-save,
  .btn-download{
    padding:0 14px;
    height:32px;
    .border-radius(4px);
    .font-size(16, 32px);

    /*.ico-ios,
    .ico-gplay{
      position:relative;
      top:-2px;
      display:inline-block;
      .size(36px, 20px);
      background-position:0 center;
      background-repeat:no-repeat;
      background-size:auto 20px;
      vertical-align:middle;
    }
    .ico-ios{
      background-image:url(../img/ico-apple.svg);
    }
    .ico-gplay{
      background-image:url(../img/ico-google-play.svg);
    }*/
  }
  .btn-save{
    min-width:100px;
  }

  .btn-gray{
    min-width:100px;
    height:32px;
    padding:0 14px;
    .border-radius(4px);
    .font-size(16, 32px);
  }
  /*.btn-red_{
    display:inline-block;
    min-width:170px;
    height:40px;
    padding:0 18px;
    .border-radius(10px);
    border:1px solid @red;
    .font-size(18, 40px);
    font-weight:700;
    color:#E2E2E2;
    background:@red;
    vertical-align:top;
    text-decoration:none;
    text-align:center;
    cursor:pointer;
    outline:0;

    &:focus{
      outline:none;
    }
  }*/

  .btn-detail,
  .btn-fav{
    margin:0 0 0 4px;
    .sq(26px);
    background-size:12px auto;
  }

  .btn-more{
    .font-size(16, 22px);

    i{
      .size(16px, 22px);
      background-size:16px auto;
    }
  }

  .btn-remove{
    padding:0 0 0 22px;
    .font-size(16, 32px);

    &:before{
      .size(16px, 32px);
      background-size:16px auto;
    }
  }


  /* ---------
  TABS
  --------- */
  .react-tabs{
    .react-tabs__tab-list{
      margin:0 0 20px 0;
    }
    .react-tabs__tab{
      padding:10px 16px;
      border:0;
      font-size:14px;
    }
  }

  /* ---------
  BREADCRUMBS
  --------- */
  .breadcrumbs{
    margin:0 0 16px 0;
    padding:8px 10px;

    li{
      &:before{
        .font-size(10, 18px);
      }
      a,
      span{
        .font-size(14, 18px);
      }
    }
  }

  /* ---------
  LAYOUT
  --------- */
  .in{
    padding-left:30px;
    padding-right:30px;
  }

  /* ---------
  SECTION
  --------- */
  section{
    padding:10px 0 0 0;
  }

  /* ---------
  DASHBOARD
  --------- */
  .camera-item-head{
    padding:8px 60px 10px 10px;
    line-height:32px;

    h2{
      .font-size(18, 1.2em);
    }
    .ico-arrow{
      right:8px;
      margin-top:-16px;
      .sq(32px);
      background-size:14px auto;
    }
  }

  /* ---------
  CAMERA LIST
  --------- */
  .camera-list-4{
    //margin:0 -8px;
    padding:8px 10px;
  }

  /* ---------
  CAMERA ITEM
  --------- */
  .camera-head{
    padding:0 80px 6px 0;

    h3{
      a{
        .font-size(16, 26px);
      }
    }
  }

  /* ---------
  RECORD - ASIDE
  --------- */
  .record-aside{
    padding-left:10px;
  }

  /* ---------
  RECORD - ASIDE - SETTINGS
  --------- */
  .record-aside-settings-content{
    .row-from,
    .row-to{
      margin:0 0 14px 0;

      span{
        display:block;
        float:none;
        padding:0 0 6px 0;
        width:100%;
        .font-size(12, 1.2em);
      }
      .row-item-date{
        padding-left:0;
        width:60%;
      }
      .row-item-time{
        width:40%;
      }
    }
    .MuiFormControl-root{
      width:100%;
    }
  }



  /* ---------
  RECORD - PLAYER
  --------- */
  .record-head{
    position:relative;
    padding:0 230px 8px 12px;

    h2{
      margin:0 4px 0 0;
      .font-size(16, 26px);
    }
    select{
      margin-right:20px;
    }
  }


  /* ---------
  RECORD - ASIDE - LIVE switch
  --------- */
  /*.record-aside-live-info{

  }*/

  /* ---------
  RECORD - ASIDE - NAV / ACTION
  --------- */
  .record-aside-action{
    margin:0 0 4px 0;
  }

  /* ---------
  RECORD - ASIDE - SETTINGS
  --------- */
  .record-aside-settings{
    margin:0 0 4px 0;
  }

  /* ---------
  RECORD - ASIDE - LIST
  --------- */
  .record-aside-list{
    width:100%;

    div.no-items {
      background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgb(0,0,0) 70%);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 40px 30px 50px 30px;
      font-size: 18px;
      line-height: 27px;
      text-align: center;

      strong {
        font-weight: lighter;
        color: #2E83F4;
      }

      button {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
        background: #2E83F4;
        color: white;
        width: 165px;
        height: 40px;
        border-radius: 10px;
      }
    }

    li{
      margin:4px 0 0 0;

      &:first-child{
        margin-top:0;
      }
    }
    a{
      padding:0 60px 0 50px;
      .size(100%, 32px);
      line-height:32px;

      .ico-person,
      .ico-car,
      .ico-animal,
      .ico-other{
        .size(40px, 100%);
        background-size:auto 16px;
      }
      .ico-other{
        background-size:auto 14px;
      }
      .date{
        .font-size(14, 1.2em);
      }
      .info{
        padding:0 8px 0 0;
        .font-size(12, 32px);
      }
    }
  }



  /* ---------
  ANALYTICS
  --------- */
  .analytics{
    .item-desc{
      padding:180px 0;
    }
    h1{
      .font-size(28, 1.2em);
    }
    p{
      .font-size(14, 1.4em);
    }
  }







  /* ---------
  MOBILE APP - LIST
  --------- */
  .mobileapp-content{
    padding-right:60px;

    h1{
      margin:0 0 20px 0;
      .font-size(18, 1.2em);
    }
    .p-empty{
      margin:20px 0;
      padding:0 0 0 32px;
      .font-size(16, 26px);
      background-position:6px center;
    }
  }
  .mobileapp-list{
    margin:0 0 20px 0;

    .item-head{
      padding:6px 40px 6px 40px;

      min-height:54px;
      line-height:40px;
      background-position:8px center;

      h3{
        .font-size(16, 1.2em);
      }
      span{
        .size(32px, 100%);
        background-size:12px auto;
      }
    }
  }

  /* ---------
  MOBILE APP - LIST - phone detail
  --------- */
  .mobileapp-item-content{
    padding:6px 6px 10px 6px;

    .item-name{
      margin:0 -6px 10px;

      input{
        padding:8px 10px;
        .font-size(16, 26px);
      }
    }
    .item-hash{
      margin:0 0 20px 0;
      padding:8px 10px;

      span{
        .font-size(12, 1.4em);
      }
    }

    h4{
      margin:0 0 10px 0;
      .font-size(16, 1.2em);
    }
    .item-object-list{
      padding:0 0 30px 0;

      label{
        margin:0 0 10px 0;
        padding:0 10px 0 26px;
        .font-size(14, 20px);
      }
      .like-checkbox{
        .sq(18px);
      }
    }
  }


  /* ---------
  MOBILE APP - right aside
  --------- */
  .mobileapp-install{
    h2{
      margin:0 0 20px 0;
      .font-size(18, 1.2em);
    }
  }
  .mobileapp-install-phone{
    width:60%;
    max-width:300px;
  }
  .mobileapp-install-apps{
    padding-left:20px;
    width:40%;

    .item{
      margin:0 0 30px 0;
      width:120px;
    }
    .ico-gplay,
    .ico-ios{
      display:block;
      margin:0 0 10px 0;
      .size(100%, 26px);
      background-size:auto 100%;
    }
    .item-img{
      margin:0 auto 10px;
    }
    p{
      .font-size(14, 1.5em);
    }
  }


  /* ---------
  SETTINGS - FORM
  --------- */
  .settings-form{
    h3{
      margin:0 0 10px 0;
      .font-size(16, 1.2em);
    }
    .form-footer{
      padding:0 14px;
      text-align:right;

      .btn-gray{
        margin:0 10px 0 0;
        min-width:100px;
      }
    }
  }


}


@media screen and (max-width: 1140px){

  .btn-download-ico{
    .sq(32px);
  }

  /* ---------
  RECORD - CLIP
  --------- */
  .record-clip{
    padding:10px;
  }
  .record-clip-editor{
    //min-height:40px;
    min-height:32px;

    .clip-start{
      i{
        margin-left:8px;
      }
    }
    .clip-end{
      i{
        margin-right:8px;
      }
    }
    label{
      padding:0 12px;
      height:32px;
      font-size:14px;
      line-height:32px;

      i{
        //top:-2px;
        .sq(12px);
        background-size:12px auto;
      }
    }
    input{
      padding:0 10px;
      width:90px;
      height:32px;
      border:0;
      font-size:14px;
      line-height:32px;
    }
  }
  .record-clip-settings{
    padding-left:10px;
  }
  .record-clip-settings-params{
    min-height:32px;
    //line-height:32px;

    p{
      margin:0 20px 0 0;
      font-size:12px;
    }
  }



  /* ---------
  RECORD - ASIDE - LIST
  --------- */
  .record-aside-list{
    div.no-items {
      padding: 20px 16px 40px 16px;
      font-size: 16px;
      line-height: 1.4em;
    }
  }




  /* ---------
  USER LIST - TABLE
  --------- */
  .users-list{
    th{
      padding:2px 6px 2px 10px;
      border-bottom:2px solid #0F1114;
    }
    .th-name{
      .font-size(14, 1.2em);
    }
    .th-input{
      input{
        width:126px;
        padding:4px 6px;
        .font-size(12, 18px);
      }
    }
    .th-sort{
      padding-right:18px;
    }
    td{
      padding:6px 10px;
      .font-size(12, 1.2em);
    }
    .btn-edit{
      .sq(20px);
      background-size:12px auto;
    }
  }

}




@media screen and (max-width: 1020px){



  /* ---------
  BREADCRUMBS
  --------- */
  .breadcrumbs{
    margin:0 0 16px 0;
    padding:6px 0;

    li{
      &:before{
        .font-size(10, 16px);
      }
      a,
      span{
        .font-size(12, 16px);
      }
    }
  }


  /* ---------
  SWITCH - LIVE Vs. RECORD
  --------- */
  .camdetail-switch{
    padding:3px;
    height:26px;

    button{
      padding:0 10px;
      height:20px;
      .font-size(12, 20px);
    }
  }



  /* ---------
  CAMERA LIST
  --------- */
  .camera-list-4{
    .camera-list-item{
      width:50%;
    }
  }




  /* ---------
  RECORD - PLAYER
  --------- */
  .record-head{
    padding:0 160px 8px 0px;

    h2{
      margin:0 8px 0 0;
      .font-size(14, 26px);
    }
    select{
      margin-right:10px;
    }
  }



  /* ---------
  ANALYTICS
  --------- */
  .analytics{
    .item-desc{
      padding:100px 0;
      max-width:50%;
    }
    h1{
      .font-size(22, 1.2em);
    }
    p{
      .font-size(12, 1.4em);
    }
  }






  /* ---------
  SETTINGS - FORM
  --------- */
  .settings-form{
    .form-item-4{
      width:33.3333%;
    }
  }



}




@media screen and (max-width: 940px){




  /* ---------
  RECORD - ASIDE - SETTINGS
  --------- */
  .record-aside-settings-content{
    .row-from,
    .row-to{
      margin:0 0 14px 0;

      span{
        display:block;
        float:none;
        padding:0 0 6px 0;
        width:100%;
        .font-size(12, 1.2em);
      }
      .row-item-date,
      .row-item-time{
        float:none;
        padding:0;
        width:100%;

        input{
          .font-size(12, 28px);
        }
      }
      .row-item-date{
        margin:0 0 6px 0;
      }
    }
    .MuiFormControl-root{
      width:100%;
    }
  }


  /* ---------
  MOBILE APP - LIST
  --------- */
  .mobileapp-content{
    float:none;
    padding-right:0;
    width:100%;
  }

  /* ---------
  MOBILE APP - right aside
  --------- */
  .mobileapp-install{
    float:none;
    padding:40px 0 0 0;
    width:100%;
    text-align:center;
  }
  .mobileapp-install-phone{
    display:none;
  }
  .mobileapp-install-apps{
    float:none;
    padding:0;
    width:100%;
    text-align:center;

    .item{
      display:inline-block;
      width:140px;
      vertical-align:top;
    }
  }


  /* ---------
  USER LIST - TABLE
  --------- */
  .users-list{
    table{
      min-width:1000px;
    }
  }


}


@media screen and (max-width: 900px){

  /* ---------
  RECORD - PLAYER
  --------- */
  .record-content{
    float:none;
    margin:0 0 20px 0;
    width:100%;
  }


  /* ---------
  RECORD - ASIDE
  --------- */
  .record-aside{
    position:relative;
    right:0;
    top:0;
    padding-left:0;
    .size(100%, auto);
  }
  .record-aside-settings-content{
    .row-from,
    .row-to{
      margin:0 0 10px 0;

      span{
        float:left;
        padding:0;
        width:14%;
        .font-size(12, 32px);
      }
      .row-item-date,
      .row-item-time{
        float:left;
        padding-left:10px;
        height:32px;
      }
      .row-item-date{
        margin:0;
        width:52%;
      }
      .row-item-time{
        width:34%;
      }
    }
  }

  .record-player-error{
    p{
      .font-size(14, 1.4em);
    }
  }



}



@media screen and (max-width: 700px){


  /* ---------
  BTNs
  --------- */
  .btn-more{
    margin:0 0 10px 0;
    .font-size(14, 22px);
  }



  /* ---------
  TABS
  --------- */
  .react-tabs{
    .react-tabs__tab-list{
      margin:0 0 10px 0;
    }
    .react-tabs__tab{
      padding:8px 10px;
      font-size:12px;
    }
  }


  /* ---------
  LAYOUT
  --------- */
  .in{
    padding-left:20px;
    padding-right:20px;
  }

  /* ---------
  FOOTER
  --------- */
  .support{
    right:20px;
  }
  .support-btn{
    padding:0 10px 0 30px;
    height:30px;
    .font-size(12, 30px);
    background-position:8px center;
    background-size:auto 12px;
  }
  .support-content{
    padding:20px 10px;

    h3{
      margin:0 0 20px 0;
      .font-size(18, 1.2em);
    }
  }
  .support-type-phone,
  .support-type-email{
    padding:30px 20px 0;
    .font-size(14, 1.2em);
    background-size:20px auto;
  }



  /* ---------
  DASHBOARD
  --------- */
  .camera-item-head{
    padding:6px 50px 8px 8px;
    line-height:28px;

    h2{
      .font-size(16, 1.2em);
    }
    .ico-arrow{
      right:8px;
      margin-top:-14px;
      .sq(28px);
    }
  }

  /* ---------
  CAMERA LIST
  --------- */
  .camera-list-item{
    margin:0 0 20px 0;
  }


  /* ---------
  CAMERA ITEM
  --------- */
  .camera-head{
    h3{
      a{
        .font-size(14, 26px);
      }
    }
  }




  /* ---------
  RECORD - PLAYER
  --------- */
  .record-head{
    h2{
      display:block;
      margin:0 0 4px 0;
      .font-size(12, 1.2em);
    }
  }
  .record-head-item{
    margin:0 14px 0 0;
    width:140px;

    select{
      margin:0;
      width:100%;
    }
  }
  .record-head-action{
    padding-top:18px;
  }

  /* ---------
  RECORD - CLIP
  --------- */
  .record-clip{
    display:block;
  }
  .record-clip-editor{
    margin:0 0 8px 0;
    width:100%;
    //display:block;

    /*.clip-start{
      border-right:0;
      border-bottom:1px solid #1C2026;
    }
    .clip-end{
      label{
        order:1;
        .border-radius(0);
      }
      input{
        order:2;
      }
    }
    label{
      width:100px;
    }*/
  }
  .record-clip-settings{
    width:100%;
    //display:block;
    padding:0;
    //text-align:right;
  }
  .record-clip-settings-params{
    //display:block;
    //line-height:1.2em;

    p{
      //margin:0 0 6px 14px;
    }
  }



  /* ---------
  ANALYTICS
  --------- */
  .analytics{
    background:none;

    .item-desc{
      padding:40px 0;
      max-width:100%;
      text-align:center;
    }
  }




  /* ---------
  SETTINGS
  --------- */
  .settings{
    padding:14px;
  }

  /* ---------
  SETTINGS - FORM
  --------- */
  .settings-form{
    margin:0 -8px;

    h3{
      margin:0 0 10px 0;
      .font-size(16, 1.2em);
    }

    .form-footer{
      padding:0 8px;
    }

    .form-item-list,
    .form-item-4{
      margin:0 0 20px 0;
      padding:0 8px;
    }
    .form-item-4{
      width:50%;
    }
    label{
      span{
        .font-size(12, 1.4em);
      }
      input,
      select{
        padding:6px 10px;
        .font-size(12, 18px);
      }
    }

    .form-item-checkbox,
    .form-item-radio{
      margin:0 0 14px 0;
      padding:0 10px 0 28px;
      .font-size(12, 20px);
    }
    .form-item-checkbox{
      .like-checkbox{
        .sq(18px);
      }
    }
    .form-item-radio{
      .like-radio{
        .sq(18px);

        &:after{
          .sq(10px);
        }
      }
    }
  }

}




@media screen and (max-width: 560px){


  /* ---------
  SWITCH - LIVE Vs. RECORD
  --------- */
  .camdetail-switch{
    display:block;
    margin-bottom:4px;
  }

  /* ---------
  RECORD - PLAYER
  --------- */
  .record-head{
    padding:0 120px 0 0;

    h2{
      float:left;
      margin:0;
      width:70px;
      line-height:26px;
    }
  }
  .record-head-item{
    margin:0 0 6px 0;
    width:220px;
    .c;

    select{
      float:left;
      //margin:0 0 0 80px;
      width:150px;
    }
  }
  .record-head-action{
    padding-top:0;
  }




  /* ---------
  MOBILE APP - right aside
  --------- */
  .mobileapp-install{
    padding:20px 0 0 0;

    h2{
      margin:0 0 10px 0;
      .font-size(16, 1.2em);
    }
  }





  /* ---------
  CAMERA LIST
  --------- */
  .camera-list-4{
    .camera-list-item{
      width:100%;
    }
  }





}


@media screen and (max-width: 480px){



  .btn-download-ico{
    .sq(28px);
    background-size:10px auto;
  }


  /* ---------
  LAYOUT
  --------- */
  .in{
    padding-left:10px;
    padding-right:10px;
  }

  /* ---------
  RECORD - CLIP
  --------- */
  .record-clip{
    padding:6px;
  }
  .record-clip-editor{
    min-height:28px;

    .clip-start{
      i{
        margin-left:4px;
      }
    }
    .clip-end{
      i{
        margin-right:4px;
      }
    }
    label{
      padding:0;
      width:76px;
      height:28px;
      font-size:12px;
      line-height:28px;
      text-align:center;

      i{
        //top:-2px;
        .sq(10px);
        background-size:10px auto;
      }
    }
    input{
      padding:0 6px;
      width:80px;
      height:28px;
      font-size:12px;
      line-height:28px;
    }
  }
  .record-clip-settings-params{
    min-height:28px;
  }




  /* ---------
  MOBILE APP - LIST
  --------- */
  .mobileapp-content{
    h1{
      margin:0 0 10px 0;
      .font-size(16, 1.2em);
    }
  }
  .mobileapp-list{
    margin:0 0 10px 0;

    .item-head{
      padding:4px 30px;
      min-height:46px;
      line-height:38px;
      background-position:8px center;
      background-size:12px auto;

      h3{
        .font-size(14, 1.2em);
      }
      span{
        .size(24px, 100%);
      }
    }
  }

  /* ---------
  MOBILE APP - LIST - phone detail
  --------- */
  .mobileapp-item-content{
    .item-name{
      input{
        padding:6px 10px;
        .font-size(14, 18px);
      }
    }
    .item-hash{
      span{
        .font-size(10, 1.4em);
      }
    }
    .item-object-list{
      label{
        width:50%;
      }
    }
  }



  /* ---------
  SETTINGS
  --------- */
  .settings{
    padding:10px;
  }

  /* ---------
  SETTINGS - FORM
  --------- */
  .settings-form{
    .form-item-4{
      width:100%;
    }
  }

}


@media screen and (max-width: 370px){

  /* ---------
  TABS
  --------- */
  .react-tabs{
    .react-tabs__tab{
      padding:8px 8px;
      font-size:11px;
    }
  }



  /* ---------
  POPUP
  --------- */
  .popup-overlay{
    padding:14px;

    .header{
      margin:0 0 6px 0;
      .font-size(18, 1.2em);
    }
    .content{
      margin:0 0 20px 0;
      font-size:14px;
    }
    .btn-cancel,
    .btn-danger{
      margin:0 4px;
      min-width:100px;
      padding:0 10px;
      height:34px;
      .font-size(14, 32px);
    }
  }


  /* ---------
  SWITCH - LIVE Vs. RECORD
  --------- */
  .camdetail-switch{
    margin-bottom:24px;
  }

  /* ---------
  RECORD - PLAYER
  --------- */
  .record-head{
    min-height:100px;

    h2{
      float:none;
      margin:0 0 4px 0;
      width:100px;
      line-height:1.2em;
    }
  }
  .record-head-item{
    width:100%;

    select{
      float:none;
      //margin:0 0 0 80px;
      width:100%;
    }
  }
  .record-head-action{
    padding-top:18px;
  }


  /* ---------
  RECORD - CLIP
  --------- */
  .record-clip{
    display:block;
  }
  .record-clip-editor{
    margin:0 0 12px 0;

    .clip-start,
    .clip-end{
      width:50%;
    }
    label{
      width:70px;
    }
    input{
      width:100%;
    }
  }
  .record-clip-settings{
    text-align:left;
  }
  .record-clip-settings-params{
    p{
      //margin:0 8px 6px 0;
    }
  }


  /* ---------
  MOBILE APP - LIST - phone detail
  --------- */
  .mobileapp-item-content{
    .item-object-list{
      label{
        width:100%;
      }
    }
  }





  /* ---------
  FOOTER
  --------- */
  .support{
    right:10px;
  }
  .support-content{
    padding:14px 10px;

    h3{
      margin:0 0 16px 0;
      .font-size(14, 1.2em);
    }
  }
  .support-type-phone,
  .support-type-email{
    padding:30px 10px 0;
    .font-size(12, 1.2em);
  }

  /* ---------
  ANALYTICS
  --------- */
  .analytics{
    h1{
      .font-size(20, 1.2em);
    }
  }

}
.torlin-table {
  div.pagination {
    @media screen and (max-width: 1091px) {
      font-size: 14px;
    }

    button {
      width: 52px;

      &.btn-prev {
        margin-right: 20px;
      }

      &.btn-next {
        margin-left: 20px;
      }
    }

    input {
      padding-left: 0;
      width: 50px;
    }

    select {
      width: 70px;
      background-position: 45px center;
    }

    @media screen and (max-width: 870px) {
      div.on-page {
        position: static;
        margin-top: 40px;
      }
    }
    @media screen and (max-width: 820px) {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

div.footfall {
  @media screen and (max-width: 800px) {
    div.footfall-header {
      display: block;

      div.filter {
        margin: 20px 0 20px 0;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 447px) {
  }
}

